export default {
  general: {
    yes: "Ja",
    no: "Nein",
    none: "Keine",
    thankYou: "Vielen Dank",
    back: "Zurück",
    backUpperCase: "ZURÜCK",
    confirm: "Bestätigen",
    further: "Weiter",
    toOverview: "Zur Übersicht",
    toOverviewUpperCase: "ZUR ÜBERSICHT",
    toStart: "Zur Startseite",
    contact: "Kontakt",
    logout: "Logout",
    overview: "Übersicht",
    appointment: "Terminvereinbarung",
    shipmentTracking: "Sendungsverfolgung",
    impressum: "Impressum",
    privacy: "Datenschutz",
    aboutUs: "Über Uns",
    faq: "FAQ",
    euro: "€",
    clock: " Uhr",
    from: "von",
    steps: "##stepping## Schritten",
    address: "Industriestraße 12-14,<br />\r\n 15366 Hoppegarten<br />",
    companyName: "Rhenus Home Delivery GmbH"
  },
  uidemo: {
    title: "Designsystem Komponenten"
  },
  contactForm: {
    questions:
      'Antworten auf häufig gestellte Fragen finden Sie <a class="link2" href="/de/faq">hier</a>. Antwort auf Fragen zu Ihrer Sendung nicht dabei? Dann kontaktieren Sie uns gerne über das unten stehende Formular.',
    pleaseContact: "Unsere Kolleg:innen antworten Ihnen in den nächsten 2-3 Arbeitstagen.",
    yourMessage: "Ihre Anfrage",
    pleaseSelectLarge: "Wählen Sie ein Thema aus",
    pleaseSelect: "Bitte wählen",
    entry: "Angaben",
    name: "Name",
    orderNumber: "Auftragsnummer",
    postcode: "Postleitzahl",
    email: "E-mail",
    telephone: "Telefon",
    message: "Ihre Nachricht",
    dataProtectionFirst: "Ich habe die",
    dataProtectionSecond:
      "zur Kenntnis genommen und stimme der Verarbeitung der von mir in das Kontaktformular eingegebenen personenbezogenen Daten wie dort beschrieben zu.",
    send: "Absenden",
    appointment: "Ich möchte einen Termin vereinbaren",
    change: "Ich möchte meinen Termin ändern / absagen",
    additionalService: "Ich wünsche einen Zusatzservice / eine Montage / eine Installation",
    orderStatus: "Wo ist meine Sendung / Frage zum Sendungsstatus",
    other: "Ich kann meine Anfrage keinem der Punkte zuordnen",
    requestReceived:
      "Ihre Anfrage ist bei uns eingegangen und wird so schnell wie möglich bearbeitet!",
    address: "Industriestraße 12-14,<br />\r\n 15366 Hoppegarten<br />",
    companyName: "Rhenus Home Delivery GmbH",
    changeOfAddress: "Ich möchte eine Adressänderung mitteilen",
    changeOfContactDetails: "Ich möchte meine Kontaktdaten ändern (bspw. Telefonnummer / Email-Adresse)",
    deliveryRestrictions: "An meiner Anlieferadresse gibt es Besonderheiten zu beachten",
    documentRequest: "Ich habe keinen Sendungsbeleg erhalten",
    orderCancellation: "Ich möchte meine Sendung stornieren",
    complaint: "Ich habe eine Beschwerde",
    orderQuestions: "Ich habe Fragen zu meiner Sendung",
    privacyQuestions: "Ich habe Fragen zum Datenschutz"
  },
  impressum: {
    title: "Impressum",
    text:
      " Rhenus Home Delivery GmbH<br />\r\n " +
      "Industriestraße 12-14<br />\r\n " +
      "15366 Hoppegarten<br />\r\n " +
      "Germany<br />\r\n  <br />\r\n " +
      "Telefon: +49 (0) 3342 / 308 20  <br />\r\n  " +
      "Telefax: +49 (0) 3342 / 387 209  <br />" +
      " <br />\r\n       " +
      'E-Mail: <a href="mailto:hd-service@de.rhenus.com">hd-service@de.rhenus.com</a> oder Kontaktformular<br />\r\n' +
      'Internet: <a href="http://www.rhenus.com">www.rhenus.com</a><br />\r\n  <br />\r\n' +
      "Geschäftsführer: Pedro Lopez, Michael Paatz, Patrick Renziehausen, Nils Thiesen<br />\r\n  <br />\r\n" +
      "Inhaltlich verantwortlich gemäß §6 MDStV: Boris Jeggle<br />\r\n <br />\r\n       " +
      "Zuständiges Amtsgericht: Hamm <br />\r\n  Handelsregisternummer: HRB 6396 <br />\r\n" +
      "Ust.Id.-Nr.: DE136697556 <br />\r\n   <br />\r\n  Bildnachweis<br />\r\n" +
      "© Tom – Fotolia.com<br />\r\n " +
      "© yulias07 – Fotolia.com<br />\r\n   " +
      "© Ron-Heidelberg – Fotolia.com<br />\r\n   " +
      "© angelha – Fotolia.com<br />\r\n  " +
      "© Taras Livyy – Fotolia.com<br />\r\n  " +
      "© Kamaga – Fotolia.com<br />\r\n " +
      "© petovarga – Fotolia.com<br />"
  },
  gtc: {
    title:
      "Allgemeine Geschäftsbedingungen der Rhenus Home Delivery GmbH für die Vereinbarung von kostenpflichtigen Zeitfenstern mit dem Endkunden im Rahmen der Belieferung",
    text:
      "    Die Rhenus Home Delivery GmbH (nachfolgend „RHD“) liefert im Auftrag ihrer Mandanten Ware an private und sonstige Empfänger. Die RHD oder der Mandant vereinbart dabei regelmäßig mit dem Empfänger (nachfolgend auch „Endkunde“) den Liefertag und ggf. einen noch enger eingegrenzten Lieferzeitraum.<br />    <br />    <b>I. Vertragsgegenstand</b><br />    <br />    RHD bietet dem Endkunden für Lieferungen in definierten Gebieten an, die angebotene Lieferzeit weitergehend einzugrenzen, " +
      "z. B. auf ein 2- oder 3-Stunden-Zeitfenster nach Wahl des Endkunden, auf den Samstag, oder die Lieferung zeitlich kurzfristiger vorzunehmen, z.B. nächster Werktag („Sonderterminzusage“).Für diese Sonderterminzusage schließen der Endkunde und RHD einen eigenständigen Vertrag ab, unter dem RHD die Lieferung innerhalb des vereinbarten Zeitfensters zusagt, während der Endkunde dafür ein Entgelt an RHD zahlt, wenn dieses Zeitfenster eingehalten wird.<br />    <br />    <b>II. Geltung dieser Allgemeinen Geschäftsbedingungen; Vertragsschluss</b><br />" +
      "    <br />    Diese Allgemeinen Geschäftsbedingungen gelten für alle, auch künftige, Vereinbarungen von Sonderterminzusagen zwischen der RHD und einem Endkunden.Die Vereinbarung kommt durch Bestätigung seitens der RHD in Schriftform, per Email oder auf sonstige Weise zustande. " +
      "Die Lieferung der Ware an den Endkunden innerhalb des angefragten Zeitfensters stellt ebenfalls eine Annahme des Angebots des Endkunden auf Vereinbarung der Zeitzusage dar.<br />    <br />    <b>III. Rechtsfolge bei Nichteinhaltung der Zeitzusage; KEIN SCHADENERSATZ</b><br />  " +
      "  <br />    Sollte RHD die Ware nicht innerhalb des Zeitfensters an den Endkunden liefern, entfällt der Entgeltanspruch der RHD aus der Vereinbarung über die Zeitzusage ersatzlos; weitere Ansprüche, z.B. auf Schadenersatz, entgangenen Gewinn, Ersatz vergeblicher Aufwendungen o.ä. stehen dem Endkunden nicht zu. Weiterhin hat die Zeitzusage der RHD keinen Einfluss auf den zwischen dem Endkunden und dem Mandanten (ggf. durch RHD in dessen Auftrag) vereinbarten Lieferzeitraum.<br />    <br />    <b>IV. Widerrufsrecht</b><br />   " +
      " <br />    Dem Endkunden, der eine natürliche Person ist, die die Vereinbarung zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (Verbraucher), steht das Recht zu, binnen vierzehn Tagen ab Vertragsschluss ohne Angabe von Gründen diese Vereinbarung zu widerrufen.Um das Widerrufsrecht auszuüben, muss eine eindeutige Erklärung, z. B. Brief, Fax oder E-Mail, über den Entschluss, diese Vereinbarung zu widerrufen, an folgende Anschrift abgegeben werden: <br /> " +
      "   <br />    Rhenus Home Delivery GmbH<br />    Industriestraße 12-14<br />    15366 Hoppegarten<br />  " +
      '  Germany<br />    <br />    Telefon: +49 (0) 3342 / 308 20  <br />    Telefax: +49 (0) 3342 / 387 209  <br />    <br />    E-Mail: <a href="mailto:hd-service@de.rhenus.com">hd-service@de.rhenus.com</a><br />    <br />    Zur Wahrung der Widerrufsfrist reicht es aus, dass die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist abgesendet wird. Im Fall des Widerrufs vor Ausführung der Lieferung wird die Rhenus Home Delivery GmbH das vereinbarte Entgelt nicht vereinnahmen bzw., soweit bereits vereinnahmt, zurückerstatten. Der Widerruf ist nur bis 15:00 Uhr am zweitletzten Werktag vor dem angefragten/zugesagten Liefertags möglich.' +
      " Im Fall des Widerrufs erlischt die Sonderterminzusage; der im Übrigen mit dem Mandanten oder im Auftrag des Mandanten mit der RHD vereinbarte Liefertermin behält unverändert seine Gültigkeit, soweit nicht diesbezüglich eine andere Vereinbarung getroffen wird.",
    gtc: "AGB"
  },
  privacyNotice: {
    title: "DATENSCHUTZERKLÄRUNG",
    text:
      "<b>I.	Name und Anschrift des Verantwortlichen</b>" +
      "<br />\r\n <br />\r\n  Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist die: " +
      "<br />\r\n<br />\r\nRhenus Home Delivery GmbH" +
      "<br />\r\nIndustriestraße 12-14" +
      "<br />\r\n15366 Hoppegarten" +
      "<br />\r\nDeutschland" +
      "<br />\r\nTel.: +49 (0)3342 387-0" +
      '<br />\r\nKontakt: <a href="https://www.rhenusmydelivery.com/de/contact">www.rhenusmydelivery.com/de/contact</a>' +
      '<br />\r\nWebsite: <a href="https://www.rhenus.group/de/de/supply-chain-loesungen/home-delivery/" rel="noopener">www.rhenus.group/de/de/supply-chain-loesungen/home-delivery/</a>' +
      "<br />\r\n<br />\r\n <b>II. Name und Anschrift des Datenschutzbeauftragten</b>" +
      "<br />\r\n<br />\r\n Der Datenschutzbeauftragte des Verantwortlichen ist:" +
      "<br />\r\n<br />\r\n Gerhard Friederici" +
      "<br />\r\nRhenus Platz 3" +
      "<br />\r\n59439 Holzwickede" +
      "<br />\r\nDeutschland" +
      "<br />\r\nE-Mail: datenschutz@rhenus.com" +
      "<br />\r\nWebsite: <a href=\"https://www.rhenus.group/de/de/\" rel=\"noopener\">www.rhenus.group/de/de/</a>" +
      "<br />\r\n<br />\r\n Zum sorgsamen Schutz Ihrer Privatsphäre und zur umfassenden Vertraulichkeit im Umgang mit Ihren Daten möchten wir Ihnen nachfolgend einige Hinweise und Informationen geben:" +
      "<br />\r\n <br />\r\n <b>III. Allgemeines zur Datenverarbeitung</b>" +
      "<br />\r\n <br />\r\n <b>1. Umfang der Verarbeitung personenbezogener Daten</b>" +
      "<br />\r\n <br />\r\n Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist." +
      "<br />\r\n <br />\r\n <b>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</b>" +
      "<br />\r\n <br />\r\n Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage." +
      "<br />\r\n <br />\r\nBei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind." +
      "<br />\r\n <br />\r\nSoweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage." +
      "<br />\r\n <br />\r\nFür den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage." +
      "<br />\r\n <br />\r\nIst die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung." +
      "<br />\r\n<br />\r\n <b>3. Datenlöschung und Speicherdauer</b>" +
      "<br />\r\n<br />\r\n Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht." +
      "<br />\r\n<br />\r\n <b>IV. Bereitstellung der Website und Erstellung von Logfiles</b>" +
      "<br />\r\n<br />\r\n <b>1. Anonyme Datenerhebung</b>" +
      "<br />\r\n<br />\r\n Sie können unsere Webseiten grundsätzlich besuchen, ohne uns mitzuteilen, wer Sie sind. Wir erfahren nur technische Daten wie z.B. den Namen Ihres Internet Service Providers, die Webseite, von der aus Sie kommen, und die Webseiten, die Sie bei uns besuchen. Diese Informationen werden mit Datum und Zeitangaben für interne statistische Zwecke der Werbung, Webseitenanalyse und zur bedarfsgerechten Gestaltung unserer Webseiten ausgewertet. Sie bleiben als Nutzer hierbei völlig anonym. Pseudonyme Nutzungsprofile werden nicht erstellt." +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage der Verarbeitung</b>" +
      "<br />\r\n<br />\r\n Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs, 1 lit. f DSGVO." +
      "<br />\r\n<br />\r\n <b>3. Zweck der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 lit. f DSGVO." +
      "<br />\r\n<br />\r\n <b>4. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist." +
      "<br />\r\n<br />\r\n <b>5. Widerspruchs- und Beseitigungsmöglichkeit</b>" +
      "<br />\r\n<br />\r\n Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit." +
      "<br />\r\n<br />\r\n <b>V. Verwendung von Cookies</b>" +
      "<br />\r\n<br />\r\n <b>a.) Beschreibung und Umfang der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Wir verwenden Cookies zur Qualitätsverbesserung des Aufbaus und Inhaltes unseres Webauftritts, und um eine benutzerorientierte und möglichst reibungslose Navigation zu ermöglichen. Begrenzt auf den Zeitraum Ihres Besuchs setzen wir so genannte Session- oder Sitzungs-Cookies. Sie dienen dazu festzuhalten, welche Inhalte von Ihrem PC aus angesehen wurden, während Sie weiter surfen, und tragen darüber hinaus zur Erhöhung Ihrer Sicherheit beim Surfen bei. Verlassen Sie unsere Webseite oder klicken Sie eine Zeitlang nicht hierauf, werden diese kurzlebigen Cookies wieder gelöscht." +
      "<br />\r\n<br />\r\n Cookies können auf Ihrem PC keinen Schaden verursachen. Eine Sicherheitsgefährdung im Sinne von Viren oder Ausspähen Ihres PCs geht von ihnen nicht aus. Den Umgang mit Cookies regeln Sie selbst. Zur Zulassung, Ablehnung, Einsicht und Löschung nutzen Sie bitte die Hilfefunktion Ihres Browsers." +
      "<br />\r\n<br />\r\n Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. In den Cookies werden dabei folgende Daten gespeichert und übermittelt." +
      "<br />\r\n<br />\r\n • Spracheinstellungen" +
      "<br />\r\n<br />\r\n Wir verwenden darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen. Auf diese Weise können folgende Daten übermittelt werden:" +
      "<br />\r\n<br />\r\n • Eingegebene Suchbegriffe" +
      "<br />\r\n<br />\r\n <b>b.) Rechtsgrundlage für die Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO." +
      "<br />\r\n<br />\r\n <b>c.) Zweck der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Der Zweck der Verwendung technisch notwendiger Cookies ist die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. Für folgende Anwendungen benötigen wir Cookies:" +
      "<br />\r\n<br />\r\n • Übernahme von Spracheinstellungen" +
      "<br />\r\n<br />\r\n Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet." +
      "<br />\r\n<br />\r\n Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Website und ihre Inhalte zu verbessern. Durch die Analyse-Cookies erfahren wir, wie die Website genutzt wird und können so unser Angebot stetig optimieren. Folgende Analyse-Cookies werden verwendet:" +
      "<br />\r\n<br />\r\n • Merken von Suchbegriffen" +
      "<br />\r\n<br />\r\n In diesem Zweck liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO." +
      "<br />\r\n<br />\r\n <b>d.) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</b>" +
      "<br />\r\n<br />\r\n Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden." +
      "<br />\r\n<br />\r\n <b>VI. Registrierung</b>" +
      "<br />\r\n<br />\r\n <b>1. Beschreibung und Umfang der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren. Die Daten werden dabei in eine Eingabemaske eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte findet nicht statt. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben: " +
      "<br />\r\n<br />\r\n • Auftragsnummer, Postleitzahl" +
      "<br />\r\n<br />\r\n • Im Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert: IP-Adresse des Nutzers, Datum und Uhrzeit der Registrierung, Vergangene Anfragen" +
      "<br />\r\n<br />\r\n Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers zur Verarbeitung dieser Daten eingeholt" +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage und Zweck der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Die Registrierung dient dazu, den Nutzer Informationen zum Status seiner Sendung zukommen zu lassen. Die Ermöglichung dieses Abrufs liegt im Interesse des Nutzers. Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO." +
      "<br />\r\n<br />\r\n <b>3. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden gelöscht, wenn ihre Verarbeitung nicht mehr erforderlich ist. Auch nach Abschluss der Lieferung kann eine Erforderlichkeit, personenbezogene Daten des Vertragspartners zu speichern, bestehen, um vertraglichen oder gesetzlichen Verpflichtungen nachzukommen." +
      "<br />\r\n<br />\r\n <b><b>VII. Kontaktformular und E-Mail-Kontakt</b>" +
      "<br />\r\n<br />\r\n 1. Beschreibung und Umfang der Datenverwaltung</b>" +
      "<br />\r\n<br />\r\n Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind:" +
      "<br />\r\n<br />\r\n • Betreff der Nachricht" +
      "<br />\r\n • Name" +
      "<br />\r\n • E-Mail Adresse" +
      "<br />\r\n • Text der Nachricht" +
      "<br />\r\n<br />\r\n Für die Verarbeitung der Daten wird im Rahmen des Absendevorganges Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen." +
      "<br />\r\n<br />\r\n Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert." +
      "<br />\r\n<br />\r\n Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet sowie ggf. für die anschließende Bearbeitung des Vorgangs (z.B. Problembehebung)." +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage und Zweck der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Rechtsgrundlage für die Verarbeitung der Daten aus dem Kontaktformular ist die Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO." +
      "<br />\r\n<br />\r\n Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO." +
      "<br />\r\n<br />\r\n Zweck der Verarbeitung ist die Kontaktaufnahme. " +
      "<br />\r\n<br />\r\n Die sonstigen während des Absendevorgangs des Kontaktformulars verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen." +
      "<br />\r\n<br />\r\n <b>3. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind und die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Der Zweck ist erfüllt, wenn die jeweilige Konversation mit dem Nutzer beendet ist und die übermittelten Daten nicht zu anderen Zwecken (Vertragserfüllung o.ä.) erforderlich sind. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist. " +
      "<br />\r\n<br />\r\n Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer Frist von sieben Tagen gelöscht." +
      "<br />\r\n<br />\r\n <b>4. Widerspruchs- und Beseitigungsmöglichkeit</b>" +
      "<br />\r\n<br />\r\n Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden." +
      "<br />\r\n<br />\r\n Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall gelöscht, soweit nicht gesetzliche Aufbewahrungspflichten bestehen." +
      "<br />\r\n<br />\r\n <b>VIII. Google Analytics</b>" +
      "<br />\r\n<br />\r\n Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc.,1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Wir setzen insbesondere den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland." +
      "<br />\r\n<br />\r\n <b>1. Beschreibung und Umfang der Datenverwaltung</b>" +
      '<br />\r\n<br />\r\n Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert (vgl. dazu nachfolgend 3.a).. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.' +
      "<br />\r\n<br />\r\n Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer Website einbinden können. Der Google Tag Manager selbst erstellt keine Nutzerprofile, speichert keine Cookies und nimmt keine eigenständigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die Vereinigten Staaten übertragen werden kann (vgl. dazu nachfolgend 3.a)." +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage und Zweck für die Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Der Einsatz des Google Tag Managers und von Google Analytics erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an drr Analyse und an einer schnellen und unkomplizierten Einbindung und Verwaltung verschiedener Tools auf seiner Website. " +
      "<br />\r\n<br />\r\n Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar." +
      "<br />\r\n<br />\r\n <b>3. Sonstiges</b>" +
      "<br />\r\n<br />\r\n <b>a.) IP Anonymisierung</b>" +
      "<br />\r\n<br />\r\n Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. " +
      "<br />\r\n<br />\r\n <b>b.) Browser Plugin</b>" +
      "<br />\r\n<br />\r\n Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: tools.google.com/dlpage/gaoptout" +
      "<br />\r\n<br />\r\n <b>c.) Widerspruch gegen Datenerfassung</b>" +
      "<br />\r\n<br />\r\n Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: tools.google.com/dlpage/gaoptout" +
      "<br />\r\n<br />\r\n Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de." +
      "<br />\r\n<br />\r\n Weitere Informationen zum Google Tag Manager entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links: https://policies.google.com/privacy?hl=de und https://policies.google.com/terms?hl=de." +
      "<br />\r\n<br />\r\n <b>d.) Auftragsdatenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um." +
      "<br />\r\n<br />\r\n <b>e.) Demografische Merkmale bei Google Analytics</b>" +
      "<br />\r\n<br />\r\n Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen." +
      "<br />\r\n<br />\r\n <b>IX. LinkedIn</b>" +
      '<br />\r\n<br />\r\n Unsere Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland. Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält, wird eine Verbindung zu Servern von LinkedIn aufbaut. LinkedIn wird darüber informiert, dass Sie unsere Internetseiten mit Ihrer IP-Adresse besucht haben. Wenn Sie den "Recommend-Button" von LinkedIn anklicken und in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf unserer Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn haben. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter: https://www.linkedin.com/legal/privacy-policy' +
      "<br />\r\n<br />\r\n LinkedIn Insight Tag. Auf unseren Seiten wird der so genannte Insight Tag des sozialen Netzwerks LinkedIn eingesetzt. Dieser wird von der LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland (nachfolgend “LinkedIn”) angeboten. Das LinkedIn Insight-Tag ist ein kleiner JavaScript-Codeausschnitt, den wir unserer Webseite hinzugefügt haben." +
      "<br />\r\n<br />\r\n Das LinkedIn Insight-Tag ermöglicht das Sammeln von Daten zu den Besuchen auf unserer Webseite, einschließlich URL, Referrer-URL, IP-Adresse, Geräte- und Browsereigenschaften, Zeitstempel und Seitenansichten. Diese Daten werden verschlüsselt, innerhalb von sieben Tagen anonymisiert und die anonymisierten Daten werden innerhalb von 90 Tagen gelöscht. LinkedIn teilt keine personenbezogenen Daten mit uns, sondern bietet nur zusammengefasste Berichte über die Webseiten-Zielgruppe und die Anzeigeleistung. LinkedIn bietet außerdem ein Retargeting für Webseitenbesucher, sodass wir mit Hilfe dieser Daten zielgerichtete Werbung außerhalb unserer Website anzeigen können, ohne dass das Mitglied dabei identifiziert wird. Mitglieder von LinkedIn können die Nutzung ihrer personenbezogenen Daten zu Werbezwecken in ihren Kontoeinstellungen steuern." +
      "<br />\r\n<br />\r\n Rhenus ist für die Verarbeitung der Daten aus Insight-Tag gemeinsam mit der LinkedIn verantwortlich. Die Vereinbarung gem. Art. 26 DSGVO finden Sie hier: https://legal.linkedin.com/pages-joint-controller-addendum." +
      "<br />\r\n<br />\r\n <b>1. Zweck und Rechtsgrundlage der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Die Nutzung des LinkedIn Insight-Tag erfolgt zu dem Zweck detaillierte Kampagnenberichte zu ermöglichen und Informationen über die Besucher unserer Webseite zu gewinnen und damit unseren Werbe- und Marketinginteressen. Als Kunde von LinkedIn Marketinglösungen verwenden wir das LinkedIn Insight-Tag, um Conversions nachzuverfolgen, ein Retargeting unserer Webseitenbesucher vorzunehmen und zusätzliche Informationen über die LinkedIn-Mitglieder zu gewinnen, die sich unsere Werbeanzeigen ansehen. Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist Art. 6 Abs. 1 lit. f DSGVO, also ein berechtigtes Interesse unsererseits. Details zur Datenerhebung (Zweck, Umfang, weitere Verarbeitung, Nutzung) sowie zu Ihren Rechten und Einstellungsmöglichkeiten können Sie den Datenschutzhinweisen von LinkedIn entnehmen. Diese Hinweise von LinkedIn finden Sie unter: https://www.linkedin.com/legal/privacy-policy" +
      "<br />\r\n<br />\r\n <b>2. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden verschlüsselt, innerhalb von sieben Tagen anonymisiert und die anonymisierten Daten werden innerhalb von 90 Tagen gelöscht. Sie können als Nutzer aber auch jederzeit selbst über die Ausführung des für das Tool erforderlichen Java-Script-Codes über Ihre Browsereinstellungen entscheiden. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Ausführung von Java-Script deaktivieren oder einschränken und damit auch die Speicherung verhindern. Hinweis: Wird die Ausführung von Java-Script deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden." +
      "<br />\r\n<br />\r\n <b>3. Widerspruchs- und Beseitigungsmöglichkeit / Opt-Out</b>" +
      "<br />\r\n<br />\r\n Wenn Sie LinkedIn-Mitglied sind und nicht möchten, dass LinkedIn über unsere Website Daten über Sie sammelt und mit Ihren bei LinkedIn gespeicherten Mitgliedsdaten verknüpft, müssen Sie sich vor Ihrem Besuch unseres Internetauftritts bei LinkedIn ausloggen." +
      "<br />\r\n<br />\r\n Zudem können Sie das Cookie unabhängig von einer LinkedIn-Mitgliedschaft hier deaktivieren: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" +
      "<br />\r\n<br />\r\n Im Rahmen der gemeinsamen Verantwortlichkeit mit LinkedIn, können Sie Ihre Betroffenenrechte gem. Art. 15, 16, 17, 18, 20, 21 DSGVO sowohl bei LinkedIn als auch bei uns in Anspruch nehmen. LinkedIn übernimmt die Erfüllung der Verpflichtungen aus der DSGVO für die Verarbeitung von Insights-Daten, insbesondere die Sicherstellung der Betroffenenrechte. Falls Sie von Ihren Betroffenenrechten Gebrauch machen wollen, kontaktierten Sie bitte direkt LinkedIn." +
      "<br />\r\n<br />\r\n <b>X. Xing</b>" +
      "<br />\r\n<br />\r\n Rhenus führt ein Profil in dem sozialen Netzwerk XING, welches von der XING SE, Dammtorstraße 30, 20354 Hamburg, Deutschland, betrieben wird. Wir haben keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch XING." +
      "<br />\r\n<br />\r\n Wir können folgenden Kategorien von Daten erfahren, wenn Sie mit unserer Seite interagieren:" +
      "<br />\r\n<br />\r\n • Vor- und Nachname" +
      "<br />\r\n<br />\r\n • Geschlecht" +
      "<br />\r\n<br />\r\n • E-Mail-Adressen" +
      "<br />\r\n<br />\r\n • Telefonnummern" +
      "<br />\r\n<br />\r\n • Jobtitel und Unternehmen" +
      "<br />\r\n<br />\r\n • Ort: Stadt und Land" +
      "<br />\r\n<br />\r\n • Informationen, die Sie uns in Ihrer Nachricht uns übermitteln" +
      "<br />\r\n<br />\r\n • Wenn unsere Beiträge Ihnen gefallen oder Sie diese kommentiert oder geteilt haben" +
      "<br />\r\n<br />\r\n Um zu verhindern, dass XING die oben genannten Daten sammelt, loggen Sie sich bei XING aus. Wir verarbeiten Ihre personenbezogenen Daten aufgrund unseres berechtigten Interesses an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO sowie ggf. Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage auf den Abschluss eines Vertrages abzielt." +
      "<br />\r\n<br />\r\n Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch XING sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von XING https://www.xing.com/privacy" +
      "<br />\r\n<br />\r\n <b>XI. Google reCAPTCHA und Google-Fonds</b>" +
      "<br />\r\n<br />\r\n Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland." +
      "<br />\r\n<br />\r\n Diese Webseite nutzt zur einheitlichen Darstellung von Schriftarten – insbesondere im Rahmen des reCAPTCHA - so genannte Web-Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen." +
      "<br />\r\n<br />\r\n Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse diese Website aufgerufen wurde." +
      "<br />\r\n<br />\r\n <b>1. Zweck und Rechtsgrundlage der Datenverarbeitung </b>" +
      "<br />\r\n<br />\r\n Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet (vgl. dazu auch Ziffer IX 3.a). Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet." +
      "<br />\r\n<br />\r\n Die Speicherung und Analyse der Daten im Rahmen des reCAPTCHA erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen. Die Nutzung von Google WebFonts erfolgt ebenfalls auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner Website." +
      "<br />\r\n<br />\r\n Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar." +
      "<br />\r\n<br />\r\n <b>2. Sonstiges</b>" +
      "<br />\r\n<br />\r\n Weitere Informationen zu Google reCAPTCHA und Google Fonds entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links: https://policies.google.com/privacy?hl=de und https://policies.google.com/terms?hl=de." +
      "<br />\r\n<br />\r\n <b>XII. Kartendienste der HERE Europe B.V.</b> " +
      "<br />\r\n<br />\r\n Unsere Webseiten verwenden Kartendienste von der HERE Europe B.V., Kennedyplein 222, 5611 ZT Eindhoven, Niederlande (nachfolgend„HERE“). " +
      "<br />\r\n<br />\r\n <b>1. Zweck und Inhalt der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Zweck ist es, geographische Informationen betreffend die Lieferung der von Ihnen bestellten Ware visuell darzustellen. An here.com werden dafür die Daten der Empfängeranschrift übermittelt, damit diese in der Karte dargestellt wird. Ebenso werden Daten über den Standort des Auslieferfahrzeugs übermittelt. " +
      "<br />\r\n<br />\r\n Bei der Nutzung der Kartendienste werden von HERE auch Daten über die Nutzung der Kartenfunktionen durch Besucher der Webseiten verarbeitet. Diesbezüglich haben wir keine Kenntnis von den an HERE konkret übermittelten Daten und deren Nutzung durch HERE. " +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage für die Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Der Einsatz der HERE-Kartendienste erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO, um Ihnen als Nutzer visuell darstellen zu können, wo sich Ihre Ware zum Zeitpunkt des Aufrufs in etwa befindet. " +
      "<br />\r\n<br />\r\n Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar." +
      "<br />\r\n<br />\r\n <b>3. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die Empfängeranschrift wir in den Kartendiensten nur für den Zeitraum gespeichert, solange die Karte eingesehen wird. Aufzeichnungen Ihrer Aktivitäten innerhalb der Anwendung werden nach Angaben von HERE in der Regel nur für einen kurzen Zeitraum aufbewahrt, bevor sie anonymisiert oder pseudonymisiert werden. " +
      "<br />\r\n<br />\r\n <b>4. Sonstige</b>" +
      "<br />\r\n<br />\r\n Informationen über den Zweck und Umfang der Datenerhebung, die weitere Verarbeitung und Nutzung der Daten durch HERE sowie Ihre diesbezüglichen Rechte entnehmen Sie bitte den Datenschutzhinweisen von HERE (https://legal.here.com/de-de/privacy/policy)." +
      "<br />\r\n<br />\r\n Sie können sich an das HERE Privacy Office unter privacy@here.com wenden, um weitere Informationen über die Aufbewahrung Ihrer personenbezogenen Daten zu erhalten." +
      "<br />\r\n<br />\r\n <b>XIII.  Privacy-Management-Tool ONETRUST</b>" +
      "<br />\r\n<br />\r\n Auf unserer Webseite verwenden wir das Consent Tool namens OneTrust. Der Anbieter ist OneTrust Technology Limited, 82 St John St, Farringdon, London EC1M 4JN, Vereinigtes Königreich (UK). Mit dieser Datenschutzverwaltungssoftware bieten wir Ihnen die Möglichkeit in gesetzeskonformer Weise in das Speichern von Cookies einzuwilligen und den Widerruf der Einwilligung zu gewährleisten. Des Weiteren wird die Einwilligung für den rechtlichen Nachweis dokumentiert und das Setzen der Cookies technisch gesteuert. Dafür werden Cookies verwendet, die Ihre Cookie-Einstellungen auf unseren Webseiten speichert. So können Ihre Cookie-Einstellungen bei einem weiteren Besuch unserer Plattformen beibehalten werden, solange Sie die Cookies nicht vorher löschen. Ihre Einstellungen können Sie jederzeit anpassen." +
      "<br />\r\n<br />\r\n Weitere datenschutzrelevanten Information zu OneTrust finden Sie hier: https://www.onetrust.de/datenschutzerklaerung/" +
      "<br />\r\n<br />\r\n <b>XIV. Chat (RheBot)</b>" +
      "<br />\r\n<br />\r\n Auf unserer Webseite haben Sie die Möglichkeit über einen ChatBot (den sog. RheBot) zu kommunizieren und Daten zu übermitteln. Der RheBot beruht auf Software der Zoho Corporation B. V., Beneluxlaan 4B, 3527 HT Utrecht, Niederlande („ZOHO“). Mit ZOHO besteht eine Auftragsverarbeitungsvereinbarung." +
      "<br />\r\n<br />\r\n <b>1. Zweck und Inhalt der Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Zweck des Chatbots ist es, Ihnen eine einfache Möglichkeit zur Kommunikation mit uns zu ermöglichen und Fragen rund um unsere Dienstleistung zu beantworten. Neben Ihrer IP-Adresse werden v.a. die Informationen gespeichert, die Sie selbst eingeben. Dabei handelt es sich teilweise um personenbezogene Informationen, z.B. Ihren Namen, die Anschrift Postleitzahl) und/oder die Auftragsnummer." +
      "<br />\r\n<br />\r\n <b>2. Rechtsgrundlage für die Datenverarbeitung</b>" +
      "<br />\r\n<br />\r\n Rechtsgrundlage für die Verarbeitung der Daten ist im Regelfall Art. 6 Abs. 1 lit. f DSGVO. Es werden Informationen ausgetauscht, die Sie wünschen bzw. die wir zur Erfüllung unseres Vertrags mit dem Verkäufer der von Ihnen erworbenen Waren benötigen. Sollte im Einzelfall eine Einwilligung von Ihnen abgefragt werden, beruht die Verarbeitung ausschließlich auf Art. 6 Abs. 1 lit. a DSGVO." +
      "<br />\r\n<br />\r\n <b>3. Dauer der Speicherung</b>" +
      "<br />\r\n<br />\r\n Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus dem Chatbot ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist und die übermittelten Daten nicht zur Bearbeitung Ihrer Wünsche/Fragen anderweitig gespeichert und/oder verarbeitet werden müssen. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist." +
      "<br />\r\n<br />\r\n Daten, die zur Bearbeitung des Sachverhalts erforderlich sind, werden bis zum Abschluss der Bearbeitung verarbeitet. " +
      "<br />\r\n<br />\r\n Die Löschung der Daten erfolgt zudem für den Fall, dass gesetzliche Aufbewahrungsfristen bestehen, erst nach Ablauf diese Aufbewahrungsfristen." +
      "<br />\r\n<br />\r\n <b>4. Sonstiges</b>" +
      "<br />\r\n<br />\r\n Informationen über den Zweck und Umfang der Datenerhebung, die weitere Verarbeitung und Nutzung der Daten durch ZOHO sowie Ihre diesbezüglichen Rechte entnehmen Sie bitte den Datenschutzhinweisen von ZOHO (https://www.zoho.com/privacy.html)." +
      "<br />\r\n<br />\r\n <b>XV. Rechte der Betroffenen</b>" +
      "<br />\r\n<br />\r\n <b>1. Auskunftsrecht</b>" +
      "<br />\r\n<br />\r\n Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden." +
      "<br />\r\n<br />\r\n Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:" +
      "<br />\r\n<br />\r\n (1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;" +
      "<br />\r\n<br />\r\n (2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;" +
      "<br />\r\n<br />\r\n (3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;" +
      "<br />\r\n<br />\r\n (4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;" +
      "<br />\r\n<br />\r\n (5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;" +
      "<br />\r\n<br />\r\n (6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;" +
      "<br />\r\n<br />\r\n (7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;" +
      "<br />\r\n<br />\r\n (8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person." +
      "<br />\r\n<br />\r\n Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden." +
      "<br />\r\n<br />\r\n <b>2. Recht auf Berichtigung</b>" +
      "<br />\r\n<br />\r\n Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen." +
      "<br />\r\n<br />\r\n <b>3. Recht auf Einschränkung der Verarbeitung</b>" +
      "<br />\r\n<br />\r\n Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:" +
      "<br />\r\n<br />\r\n (1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;" +
      "<br />\r\n<br />\r\n (2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;" +
      "<br />\r\n<br />\r\n (3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder" +
      "<br />\r\n<br />\r\n (4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen." +
      "<br />\r\n<br />\r\n Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden." +
      "<br />\r\n<br />\r\n Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird." +
      "<br />\r\n<br />\r\n <b>4. Recht auf Löschung</b>" +
      "<br />\r\n<br />\r\n a) Löschungspflicht" +
      "<br />\r\n<br />\r\n Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:" +
      "<br />\r\n<br />\r\n (1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig." +
      "<br />\r\n<br />\r\n (2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung." +
      "<br />\r\n<br />\r\n (3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein." +
      "<br />\r\n<br />\r\n (4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet." +
      "<br />\r\n<br />\r\n (5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt." +
      "<br />\r\n<br />\r\n (6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben." +
      "<br />\r\n<br />\r\n <b>b) Information an Dritte</b>" +
      "<br />\r\n<br />\r\n Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben." +
      "<br />\r\n<br />\r\n <b>c) Ausnahmen</b>" +
      "<br />\r\n<br />\r\n Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist" +
      "<br />\r\n<br />\r\n (1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;" +
      "<br />\r\n<br />\r\n (2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;" +
      "<br />\r\n<br />\r\n (3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;" +
      "<br />\r\n<br />\r\n (4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder" +
      "<br />\r\n<br />\r\n (5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen." +
      "<br />\r\n<br />\r\n <b>5. Recht auf Unterrichtung</b>" +
      "<br />\r\n<br />\r\n Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden." +
      "<br />\r\n<br />\r\n Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden." +
      "<br />\r\n<br />\r\n <b>6. Recht auf Datenübertragbarkeit</b>" +
      "<br />\r\n<br />\r\n Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern" +
      "<br />\r\n<br />\r\n (1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und" +
      "<br />\r\n<br />\r\n (2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt." +
      "<br />\r\n<br />\r\n In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden." +
      "<br />\r\n<br />\r\n Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde." +
      "<br />\r\n<br />\r\n <b>7. Widerspruchsrech</b>" +
      "<br />\r\n<br />\r\n Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling." +
      "<br />\r\n<br />\r\n Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen." +
      "<br />\r\n<br />\r\n Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht." +
      "<br />\r\n<br />\r\n Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet." +
      "<br />\r\n<br />\r\n Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden." +
      "<br />\r\n<br />\r\n <b>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</b>" +
      "<br />\r\n<br />\r\n Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt." +
      "<br />\r\n<br />\r\n <b>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</b>" +
      "<br />\r\n<br />\r\n Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung" +
      "<br />\r\n<br />\r\n (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist," +
      "<br />\r\n<br />\r\n (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder" +
      "<br />\r\n<br />\r\n (3) mit Ihrer ausdrücklichen Einwilligung erfolgt." +
      "<br />\r\n<br />\r\n Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden." +
      "<br />\r\n<br />\r\n Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört." +
      "<br />\r\n<br />\r\n <b>10. Recht auf Beschwerde bei der Aufsichtsbehörde</b>" +
      "<br />\r\n<br />\r\n Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt." +
      "<br />\r\n<br />\r\n Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO."
  },
  startpage: {
    overviewTitle:
      "Sie wollen Ihren Termin vereinbaren oder Ihre Sendung verfolgen?",
    overviewDescription:
      "Hier bieten wir Ihnen die Möglichkeit die Abwicklung Ihres Auftrags mitzugestalten und zu verfolgen. Probieren Sie es aus!",
    youCan: "Hier können Sie",
    setMeeting: "Auftragstermin vereinbaren",
    orderStatusTracking: "Auftragsstatus verfolgen",
    watchOrderUpdates: "Auftragsupdates einsehen",
    trackProbablyDeliveryTime: "Sendung live verfolgen",
    getContact: "Kontakt aufnehmen"
  },
  notificationBox: {
    title: {
      new: "ES GIBT NEUIGKEITEN!",
      open: "RHENUS HOME DELIVERY"
    },
    description: {
      chooseAppointment:
        "Wir haben Ihren Auftrag erhalten. Wählen Sie unter „Terminvereinbarung“ jetzt Ihren Wunschtermin aus.",
      fixedDate:
        "Ihr Termin steht fest. Das Lieferzeitfenster wird am Vortag der Lieferung um ca. 15 Uhr auf 2 Stunden eingegrenzt. Diese und weitere Informationen rund um Ihre Lieferung können Sie unter „Sendungsverfolgung“ einsehen.",
      deliveryEnRoute:
        "Das Lieferteam ist unterwegs zu Ihnen. Verfolgen Sie live Ihre Lieferung unter „Sendungsverfolgung“.",
      fixedDateChanged: "Der Liefertermin wurde aktualisiert.",
      timeWindowNarrowed:
        "Ihr Termin konnte weiter eingrenzt werden. Sobald das Lieferteam unser Lager mit Ihrem Auftrag verlassen hat, können Sie die Position und die voraussichtliche Ankunft des Teams live verfolgen.",
      orderComplete: "Ihr Auftrag wurde erfolgreich durchgeführt.",
      deliveryDelayed:
        "Die Zustellung Ihrer Sendung verzögert sich. Verfolgen Sie die voraussichtliche Ankunftszeit unter „Sendungsverfolgung“.",
      orderCanceled:
        "Ihr Auftrag wurde storniert. Haben Sie Fragen? Unser Servicecenter hilft Ihnen gerne weiter.",
      deliveryUnsuccessful:
        "Leider konnten wir Ihren Auftrag nicht ausführen. Falls erforderlich, kontaktieren wir Sie zeitnah für einen neuen Termin."
    }
  },
  heroLogin: {
    loginTitle: "LOGIN",
    descriptionInfoTitle: "So funktioniert's",
    orderData: "Geben Sie Ihre Zugangsdaten ein",
    descriptionInfo:
      "Bitte geben Sie Ihre Auftragsnummer und die Postleitzahl Ihrer Lieferadresse ein. Ihre Auftragsnummer entnehmen Sie Ihrem Auftrag.",
    orderNumber: "Auftragsnummer",
    zipCode: "Postleitzahl",
    send: "ANMELDEN",
    noResultTitle:
      "Ein Login mit den von Ihnen angegebenen Daten ist leider nicht möglich.",
    noResult:
      "Bitte überprüfen Sie Ihre Eingaben oder kontaktieren Sie unser Customer Service Team.",
    error:
      "Bitte überprüfen Sie die angegebenen Auftragsdaten und versuchen es später erneut."
  },
  footerContact: {
    establishContact: "Kontakt aufnehmen",
    openingHours: "Öffnungszeiten Servicecenter",
    mondayToFriday: "Montag bis Freitag",
    telephone: "Telefon:",
    time: "8:00 – 18:00 Uhr",
    phoneNumber: "+49 3342–30820",
    moreQuestions: "Sie haben weitere Fragen? Schauen Sie in unsere",
    contactCustomerService:"oder kontaktieren Sie unser Client & Customer Care Center.",
    deliveryStatusQuestion:"Sie haben Fragen zu Ihrem Sendungsstatus? Alle Informationen finden Sie auf Rhenusmydelivery.",
    redirectLoginPage:"Loggen Sie sich ein."
  },
  validationErrors: {
    invalidRequiredText: "Bitte füllen sie dieses Feld aus.",
    invalidRequiredDropdown: "Wählen Sie ein Element in der Liste aus.",
    invalidRequiredCheckbox: "Bitte ergänzen Sie Ihre Eingabe",
    invalidEmail: "Die E-Mail-Adresse ist ungültig.",
    invalidPattern:
      "Ihre Eingabe muss mit dem geforderten Format übereinstimmen.",
    invalidOrderNumber: "Bitte geben Sie eine gültige Auftragsnummer ein.",
    invalidPostCode: "Bitte geben Sie eine gültige Postleitzahl ein.",
    dataPrivacy: "Bitte akzeptieren Sie die Datenschutzerklärung.",
    captcha: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es nochmal."
  },
  faq: {
    shipmentMontagePickup: "Lieferung, Montage & Abholung",
    reclamationAndRetoure: "Reklamationen & Retouren",
    appointmentTitle1: "Wie kann ich einen Liefer-/Abholtermin vereinbaren?",
    appointmentText1:
      'Sollten Sie nicht bereits einen Termin mit Ihrem Händler vereinbart haben, kontaktieren wir Sie, sobald uns Ihr Auftrag zur Terminvereinbarung vorliegt. Dazu erhalten Sie entweder eine Email, die Sie zu unserem Online-Terminservice <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> führt, oder einen Anruf unseres Service Centers. \r\nUm den Online-Terminservice <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> zu nutzen, folgen Sie bitte dem in der Email enthaltenen Link. Unter dem Reiter Termin vereinbaren können Sie alle verfügbaren Termine einsehen und einen für Sie passenden Termin auswählen.\r\nBei der telefonischen Terminvereinbarung ruft Sie unser Service Center mit der Telefonnummer +49 (0) 3342 / 308 20 an und stimmt einen Termin mit Ihnen ab. \r\nSollten wir auf keinem dieser Wege einen Termin mit Ihnen abstimmen können, behalten wir uns vor einen Termin für Sie zu buchen.\r\nNach erfolgreicher Terminbuchung erhalten Sie eine Bestätigung per Email oder (sollte uns keine Email-Adresse vorliegen) per Post.\r\n',
    appointmentTitle2: "Welche Termine stehen mir zur Auswahl?",
    appointmentText2:
      "Liefer- und Abholtermine können bis zu 3 Wochen im Voraus vereinbart werden. Wir liefern von montags bis samstags zwischen 7:00 - 21:00 Uhr. Am Vortag der Lieferung/Abholung ab ca. 15:00 Uhr grenzen wir Ihren Termin zusätzlich auf ein voraussichtliches 2-Stunden-Zeitfenster ein. Bitte beachten Sie, dass - zum Beispiel Postleitzahlen außerhalb von Ballungsgebieten - gegebenenfalls nicht täglich beliefert werden können. Details erhalten Sie im Rahmen des Online-Terminservices oder durch unser Service Center.",
    appointmentTitle3:
      "Kann ich die Lieferung/Abholung zu einer bestimmten Uhrzeit vereinbaren?",
    appointmentText3:
      "Leider ist es nicht möglich, einen Termin zu einer bestimmten Uhrzeit zu vereinbaren. Wir liefern von montags bis samstags zwischen 7:00 - 21:00 Uhr. Am Vortag der Lieferung/Abholung ab ca. 15:00 Uhr grenzen wir Ihren Termin auf ein voraussichtliches 2-Stunden-Zeitfenster ein. Zusätzlich informiert Sie unser Lieferteam 30 bis 60 Minuten vor dem geplanten Eintreffen telefonisch über seine Ankunftszeit.",
    appointmentTitle4:
      "Kann ich eine Samstags-Lieferung/-Abholung vereinbaren?",
    appointmentText4:
      "In einigen Gebieten ist es möglich eine Samstags-Lieferung/-Abholung zu vereinbaren. Bitte beachten Sie, dass - zum Beispiel Postleitzahlen außerhalb von Ballungsgebieten - gegebenenfalls nicht täglich beliefert werden können. Details erhalten Sie im Rahmen des Online-Terminservices oder durch unser Service Center.",
    appointmentTitle5:
      "Welche Serviceoptionen kann ich zu meinem Auftrag hinzubuchen?",
    appointmentText5:
      "Je nach vertraglicher Vereinbarung mit Ihrem Händler, können weitere Serviceoptionen, wie zum Beispiel Montage oder Entsorgung von Altware (Geräte/Möbel/Matratzen), zu Ihrer Lieferung hinzugebucht werden. Für Details zu den angebotenen Zusatzservices kontaktieren Sie bitte Ihren Händler oder sprechen Sie unsere Mitarbeiter aus dem Service Center während der Terminvereinbarung an.",
    appointmentTitle6: "Wie kann ich meinen Termin ändern?",
    appointmentText6:
      'Sollten Sie eine Änderung Ihres Termins wünschen, können Sie dies gerne mit unserem Service Center Montag bis Freitag von 8:00 bis 18:00 Uhr unter der Telefonnummer +49 (0) 3342 / 308 20 abstimmen. Sofern Sie Ihren Termin über unseren Online-Terminservice www.rhenusmydelivery.com gebucht haben, können Sie diesen im Regelfall bis zu 3 Werktage vorher auch bequem online selbst verschieben. Klicken Sie dafür einfach erneut auf "Terminvereinbarung" und dann auf "Wunschtermin bearbeiten".',
    appointmentTitle7: "Kann ich meine Kontaktdaten ändern?",
    appointmentText7:
      "Zur Änderung Ihrer Kontaktdaten informieren Sie bitte Ihren Händler. Aus datenschutzrechtlichen Gründen kann Rhenus Home Delivery Änderungen Ihrer Kontakt- und Auftragsdaten in der Regel nicht vornehmen. Bitte beachten Sie, dass eine Adressänderung nach der Terminvereinbarung gegebenenfalls eine Änderung Ihres Termins zur Folge haben kann. ",
    appointmentTitle8: "Wie kann ich meine Bestellung ändern oder stornieren?",
    appointmentText8:
      "Zur Änderung Ihrer Kontaktdaten informieren Sie bitte Ihren Händler. Aus datenschutzrechtlichen Gründen kann Rhenus Home Delivery Änderungen Ihrer Kontakt- und Auftragsdaten in der Regel nicht vornehmen. Bitte beachten Sie, dass eine Adressänderung nach der Terminvereinbarung gegebenenfalls eine Änderung Ihres Termins zur Folge haben kann. ",
    appointmentTitle9: "Wofür werden meine Daten verwendet?",
    appointmentText9:
      "Wir verarbeiten Ihre Daten, wie zum Beispiel Ihre Liefer-/Abholadresse, um Ihren Auftrag erfolgreich ausführen zu können. Dabei beachten wir die geltenden Bestimmungen der DSGVO. Aus diesem Grund können wir Änderungen Ihrer Kontakt- und Auftragsdaten nur vornehmen, sofern Sie diesen im Rahmen der Datenschutzeinwilligung mit Ihrem Händler zugestimmt haben. ",
    shipmentTrackingTitle1:
      "In welchem Zeitfenster findet meine Lieferung/Abholung statt?",
    shipmentTrackingText1:
      'Am Vortag der Lieferung/Abholung ab ca. 15:00 Uhr grenzen wir Ihren Termin auf ein voraussichtliches 2-Stunden-Zeitfenster ein. Dieses können Sie auf <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> einsehen. Gegebenenfalls informieren wir Sie zusätzlich per Email.',
    shipmentTrackingTitle2: "Wie kann ich meine Lieferung/Abholung verfolgen?",
    shipmentTrackingText2:
      'Auf <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> können Sie am Vortag der Lieferung/Abholung ab ca. 15:00 Uhr Ihr voraussichtliches 2-Stunden-Zeitfenster einsehen. Am Tag der Lieferung/Abholung können Sie hier zusätzlich die voraussichtliche Ankunftszeit und die Position unseres Lieferteams in Echtzeit verfolgen. 30 bis 60 Minuten vor dem geplanten Eintreffen informiert Sie unser Team telefonisch über seine Ankunftszeit.',
    shipmentTrackingTitle3:
      "Wie melde ich mich auf www.rhenusmydelivery.com an?",
    shipmentTrackingText3:
      'Zur Anmeldung auf <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> geben Sie bitte Ihre Auftragsnummer und die Postleitzahl Ihrer Lieferadresse in die vorgesehenen Eingabefelder ein. Die Auftragsnummer entnehmen Sie unter anderem der Auftragsbestätigung Ihres Händlers sowie Ihren von Rhenus Home Delivery erhaltenen Emails. Eine Registrierung ist nicht erforderlich. Sollten Sie einem per Email erhaltenen Link zu <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> folgen, entfällt die Anmeldung.',
    shipmentTrackingTitle4:
      "Mein Zeitfenster ist abgelaufen, aber ich habe meine Lieferung nicht erhalten. Was kann ich tun?",
    shipmentTrackingText4:
      'Unser Anspruch ist es, Lieferungen innerhalb des angegebenen voraussichtlichen 2-Stunden-Zeitfensters durchzuführen. Leider kann sich der Lieferzeitpunkt aufgrund unvorhersehbarer Umstände, zum Beispiel erhöhtem Verkehrsaufkommen, verzögern. In diesem Fall empfehlen wir Ihnen unsere Sendungsverfolgung auf <a href="http://www.rhenusmydelivery.com">www.rhenusmydelivery.com</a> zu nutzen. Weitere Details entnehmen Sie bitte der Frage Wie kann ich meine Lieferung/Abholung verfolgen?',
    shipmentMontagePickupTitle1:
      "Wie kann ich zu einer erfolgreichen Ausführung meines Auftrages beitragen?",
    shipmentMontagePickupText1:
      '4 hilfreiche Tipps für ein optimales Liefererlebnis finden Sie in unserem <a href="https://youtu.be/4jHnZsadM_k" rel="noopener" style="color: rgb(0, 70, 155);">YouTube Video</a>',
    shipmentMontagePickupTitle2:
      "Muss ich zu meinem vereinbarten Termin zu Hause sein?",
    shipmentMontagePickupText2:
      "Rhenus Home Delivery ist spezialisiert auf die 2-Mann-Belieferung von sperrigen oder schweren Gütern an den Ort der Verwendung. Deshalb ist es zwingend notwendig, dass wir jemanden an der Lieferadresse antreffen, der die bestellte Ware entgegennimmt. Die Ware kann nicht an einem sicheren Ort, bei einem Nachbarn oder einer Paketstation deponiert werden.",
    shipmentMontagePickupTitle3: "Wie läuft die Lieferung ab?",
    shipmentMontagePickupText3:
      "Beim Eintreffen an der Lieferadresse meldet sich unser 2-Mann-Team bei Ihnen und trägt die Bestellung nach Rücksprache mit Ihnen an den gewünschten Ort der Verwendung, zum Beispiel Ihre neue Waschmaschine in den Keller. Je nach gebuchtem Leistungsumfang packt das Team Ihre Ware auch aus, nimmt Ihre Altware (alte Geräte/Möbel/Matratzen) mit und übernimmt die fachgerechte Entsorgung. Das Lieferteam wickelt gegebenenfalls die Zahlung des ausstehenden Rechnungsbetrags ab und dokumentiert Details Ihrer Lieferung elektronisch. Wir übermitteln Ihnen kurz nach erfolgter Lieferung einen Liefernachweis inklusive Zahlungsbestätigung per Email. ",
    shipmentMontagePickupTitle4: "Wie läuft die Montage ab?",
    shipmentMontagePickupText4:
      "Sofern Sie Ihre Ware nicht bereits im Rahmen einer separaten Lieferung erhalten haben, meldet sich unser 2-Mann-Teams bei Ihnen und trägt die bestellte Ware an Ihren gewünschten Ort der Verwendung. Anschließend bauen die Monteure die Ware nach Rücksprache mit Ihnen anhand der vorliegenden Montageanleitung auf. Bitte beachten Sie, dass nur die Artikel aufgebaut werden, für die eine Montageleistung beauftragt ist. Die Ware kann nur an der gewünschten Verwendungsstelle aufgestellt, befestigt oder aufgehängt werden, wenn die baulichen Gegebenheiten dies nach Einschätzung des Montageteams zulassen. Die Montage schließt außerdem die fachgerechte Entsorgung der Verpackung mit ein. Anschließend wickelt das Team gegebenenfalls die Zahlung des ausstehenden Rechnungsbetrags ab und dokumentiert Details Ihrer Lieferung beziehungsweise Montage elektronisch. Wir übermitteln Ihnen kurz nach dem erfolgten Service einen Nachweis, gegebenenfalls inklusive Zahlungsbestätigung, per Email.",
    shipmentMontagePickupTitle5:
      "Welche Voraussetzungen müssen für die Installation von Elektrogeräten erfüllt sein?",
    shipmentMontagePickupText5:
      "Die Verwendungsstelle der Elektrogeräte muss so gewählt sein, dass Anschlüsse frei zugänglich sind und der Anschluss ohne Verlängerung von beispielsweise Schläuchen oder Kabeln vorgenommen werden kann. Elektrogeräte können nur an bauseits vorhandenen und technisch einwandfreien Anschlüssen angeschlossen werden.",
    shipmentMontagePickupTitle6: "Wie läuft die Abholung ab?",
    shipmentMontagePickupText6:
      "Beim Eintreffen meldet sich unser 2-Mann-Team bei Ihnen. Im Falle einer Retoure übernimmt das Team auch die transportsichere Verpackung der Ware, sollte die Originalverpackung nicht mehr vorhanden sein, und nimmt die Ware mit. Das Team wickelt gegebenenfalls die Zahlung des ausstehenden Rechnungsbetrags ab und dokumentiert Details Ihrer Abholung elektronisch. Wir übermitteln Ihnen kurz nach dem erfolgten Service einen Nachweis, gegebenenfalls inklusive Zahlungsbestätigung, per Email. Anschließend wird Ihre Retoure in einen unserer Standorte gebracht, von wo aus sie an Ihren Händler zurückgeschickt wird. Im Falle einer Abholung von Altware (alte Geräte/Möbel/Matratzen) wird Ihre Ware ebenfalls in einen unserer Standorte gebracht und an einen unserer Entsorgungspartner zur fachmännischen Entsorgung übergeben.",
    shipmentMontagePickupTitle7:
      "Welche Voraussetzungen müssen für die Abholung meiner Altware erfüllt sein?",
    shipmentMontagePickupText7:
      "Bitte stellen Sie vor der Abholung sicher, dass die Ware soweit möglich demontiert wurde und nicht eingebaut oder mit der Wand verschraubt ist. Bitte trennen Sie elektrische Geräte von der Stromversorgung und achten Sie darauf, dass Geräte mit Wasseranschluss deinstalliert sind und Restwasser vollständig entleert wurde. Sollten Sie die Abholung von Altmatratzen wünschen, verpacken Sie diese bitte. Aus hygienischen Gründen können unsere Mitarbeiter unverpackte Matratzen nicht mitnehmen.",
    shipmentMontagePickupTitle8: "Wie kann ich bezahlen?",
    shipmentMontagePickupText8:
      "Falls dies mit Ihrem Händler vereinbart ist, können Sie Ihre Bestellung beziehungsweise gebuchte Serviceleistungen vor Ort bei der Zustellung bezahlen. Die Zahlung kann je nach Vereinbarung mit Ihrem Händler per EC-Karte oder bar per Nachnahme erfolgen. Serviceleistungen, die Sie direkt mit Rhenus Home Delivery vereinbart haben, sind bar zu bezahlen. Bitte halten Sie im Falle einer Barzahlung den genauen Rechnungsbetrag bereit, da das Lieferteam kein Wechselgeld bereitstellen kann. Wir übermitteln Ihnen kurz nach der erfolgten Lieferung einen Liefernachweis inklusive Zahlungsbestätigung per Email.",
    shipmentMontagePickupTitle9: "Welches Unternehmen beliefert mich?",
    shipmentMontagePickupText9:
      "Je nach Postleitzahlengebiet werden Sie durch Rhenus Home Delivery selbst oder durch einen unserer zuverlässigen Kooperationspartner beliefert.",
    shipmentMontagePickupTitle10:
      "Was passiert, wenn ich zum Zeitpunkt der Zustellung nicht zu Hause bin?",
    shipmentMontagePickupText10:
      "Sollte das Lieferteam niemanden an der angegebenen Lieferadresse antreffen, kann Ihre Ware nicht zugestellt werden. Deshalb wird die Liefertour fortgesetzt und Ihre Ware anschließend in eines unserer Lager zurückgebracht. Unser Service Team wird Sie zur Vereinbarung eines neuen Termins frühestens im Laufe des Folgetags telefonisch kontaktieren. Bitte beachten Sie, dass unser Lieferteam am selben Tag keine zweite Anfahrt zu Ihrer Lieferadresse unternehmen kann.",
    reclamationAndRetoureTitle1:
      "Meine Ware ist beschädigt/meine Lieferung ist unvollständig. Was kann ich tun?",
    reclamationAndRetoureText1:
      'Alle Lieferungen werden vor der Auslieferung auf Verpackungsschäden und Vollständigkeit der Packstücke überprüft. Leider kann es dennoch vorkommen, dass die Ware beschädigt ist oder Teile fehlen. Sollte Ihre Ware trotz intakter Verpackung beschädigt oder unvollständig sein, kontaktieren Sie bitte Ihren Händler.  Für den Fall, dass Sie beschädigte oder unvollständige Ware mit Verpackungsschäden erhalten haben, kontaktieren Sie bitte unser Service Center Montag bis Freitag von 8:00 bis 18:00 Uhr unter der Telefonnummer +49 (0) 3342 / 308 20 oder unter <a href="http://hd-service@de.rhenus.com">hd-service@de.rhenus.com</a>. Wir werden Ihre Reklamation prüfen und Sie zur Abstimmung des weiteren Vorgehens kontaktieren.',
    reclamationAndRetoureTitle2:
      "Die Montage war fehlerhaft. Was kann ich tun?",
    reclamationAndRetoureText2:
      'Sollten Sie unzufrieden mit Ihrer Montage sein, kontaktieren Sie bitte unser Service Center Montag bis Freitag von 8:00 bis 18:00 Uhr unter der Telefonnummer +49 (0) 3342 / 308 20 oder unter <a href="http://hd-service@de.rhenus.com">hd-service@de.rhenus.com</a>. Wir werden Ihre Reklamation prüfen und gegebenenfalls einen Termin zur Nachbesserung durch unsere Monteure mit Ihnen vereinbaren.',
    reclamationAndRetoureTitle3:
      "Mein Eigentum/Eigentum Dritter wurde während der Lieferung beschädigt. Was kann ich tun?",
    reclamationAndRetoureText3:
      'Sollte es während der Lieferung zu Schäden an Ihrem oder am Eigentum Dritter gekommen sein, kontaktieren Sie bitte unser Service Center Montag bis Freitag von 8:00 bis 18:00 Uhr unter der Telefonnummer +49 (0) 3342 / 308 20 oder unter <a href="http://hd-service@de.rhenus.com">hd-service@de.rhenus.com</a>. Wir werden Ihre Reklamation prüfen und Sie zur Abstimmung des weiteren Vorgehens kontaktieren.',
    reclamationAndRetoureTitle4: "Wie kann ich meine Ware zurücksenden?",
    reclamationAndRetoureText4:
      "Sollten Sie Ihre Ware zurücksenden wollen, kontaktieren Sie bitte Ihren Händler. Rhenus Home Delivery kann Ihre Retoure leider nicht für Sie veranlassen.",
    contactTitle1:
      "Wie kann ich Rhenus Home Delivery kontaktieren oder eine Rückmeldung zum Service geben?",
    contactText1:
      'Für Fragen, Anregungen und Kritik zu unserem Service können Sie uns gerne hier kontaktieren:\r\n\r\n<br/>Telefon: +49 (0) 3342 30 820 (Montag bis Freitag, 8:00 bis 18:00 Uhr)<br/>\r\n Email: <a href="http://hd-service@de.rhenus.com">hd-service@de.rhenus.com</a><br/>\r\n Kontaktformular: <a href="https://www.rhenusmydelivery.com/de/contact">rhenusmydelivery.com/de/contact</a><br/>\r\n Postweg: Rhenus Home Delivery GmbH, Industriestr. 12-14, 15366 Hoppegarten\r\n',
    anyQuestions:
      "Sie haben noch Fragen? Hier finden Sie Antworten auf häufig gestellte Fragen."
  },
  order: {
    OrderNumber: "Auftragsnummer",
    DealerName: "Händlername",
    DeliveryDate: "Liefertermin",
    ClientName: "Auftraggeber",
    DeliveryInformationTitle: "Sendung",
    NoDeliveryDateSet: "Jetzt Liefertermin vereinbaren!",
    NoDeliveryWindow: "nicht verfügbar",
    DeliveryTimeWindow: "Lieferzeitfenster",
    ActualState: "Aktueller Status",
    OrderTitle: "Ihre Sendung auf einen Blick",
    PersonalDate: "Persönliche Daten",
    ProductData: "Produktinformationen",
    ServiceInformation: "Produktinformationen",
    OrderInformation: "Sendungsinformationen",
    Hello: "Hallo",
    Delivery: "Lieferung",
    Pickup: "Abholung",
    Service: "Serviceleistung",
    Services: "Services",
    NoServices: "Es wurden keine Serviceleistungen gebucht.",
    Name: "Name",
    Street: "Straße",
    StreetDescriptionInfo:
      "Aus Datenschutzgründen kann Rhenus Home Delivery keine Änderungen vornehmen. Informieren Sie bitte Ihren Händler.",
    streetDescriptionInfoTitle: "Hinweis",
    PLZ: "PLZ",
    Location: "Ort",
    Telephone: "Telefon",
    EMail: "Email",
    Weight: "Gewicht",
    Volume: "Volumen",
    DocumentDownload: "Dokumenten download",
    NoDocuments: "Aktuell stehen keine Auftragsdokumente zum Download bereit.",
    DocumentsLoading: "Dokumente werden geladen",
    DocumentTitle: "DOKUMENTENDOWNLOAD",
    YourDocuments: "Ihre Dokumente",
    DocumentInfo:
      "Nach Abschluss des Auftrags zum Download zur Verfügung stehende Dokumente",
    NBAAppointmentTitle: "JETZT TERMIN VEREINBAREN!",
    NBAAppointmentDescription:
      "Nutzen Sie den Terminservice noch heute, um Ihren Wunschtermin zu ermöglichen.",
    NBAAppointmentButton: "ZUR TERMINVEREINBARUNG",
    NBAOrderTrackingTitle: "LIEFERUNG JETZT VERFOLGEN!",
    NBAOrderTrackingDescription:
      "Aktuelle Informationen zur Sendung finden Sie hier. Sobald das Lieferteam unser Lager mit Ihrem Auftrag verlassen hat, können Sie die Position und die voraussichtliche Ankunft des Teams live verfolgen.",
    NBAOrderTrackingButton: "ZUR SENDUNGSVERFOLGUNG",
    ServicesForOrder: "UNSERE SERVICES FÜR IHRE SENDUNG",
    CardOrderTracking: "SENDUNGSVERFOLGUNG",
    CardOrderTrackingButton: "Zur Sendungsverfolgung",
    CardOrderTrackingDescription:
      "Aktuelle Informationen zur Sendung finden Sie hier. Sobald das Lieferteam unser Lager mit Ihrem Auftrag verlassen hat, können Sie die Position und die voraussichtliche Ankunft des Teams live verfolgen.",
    CardOrderChanges: "AUFTRAG BEARBEITEN",
    CardOrderButton: "AUFTRAG BEARBEITEN",
    CardOrderDescription: "Zukünftig angedachte Leistungen:",
    CardOrderLocation: "Ablageort/Nachbarn benennen",
    CardOrderAdress: "Adresse ändern",
    CardOrderStorno: "Auftrag stornieren",
    CardExtraServices: "ZUSATZLEISTUNGEN",
    CardExtraServicesButton: "ZUSATZLEISTUNGEN ANSEHEN",
    CardExtraServicesBuildFurniture: "Möbelstück aufbauen",
    CardExtraServicesRemoveFurniture: "Altgerät/altes Möbelstück entsorgen",
    cardActualInformationTitle: "Aktuelle Informationen",
    cardActualInformationText:
      'Aktuelle Meldungen finden Sie <a href="http://cloud.cjm.cls-rhenus.com/rhdaktuelleinformationen"> hier</a>. Bitte informieren Sie sich fortlaufend über Änderungen.',
    ETC: "etc.",
    ThanksForOrder: "Ihr Auftrag wurde erfolgreich abgeschlossen.",
    DoYouHaveTime:
      "Haben Sie noch ein wenig Zeit? Bewerten Sie uns gerne auch auf Trustpilot!",
    ValuationLink: "https://de.trustpilot.com/review/rhenus.com",
    noProductInformation: "Es liegen keine Produktinformationen vor.",
    noServicesBooked: "Es wurden keine Serviceleistungen gebucht.",
    documentType: {
      Lieferschein: "Lieferschein",
      Austauschschein: "Austauschschein",
      Abholschein: "Abholschein"
    },
    orderState: {
      delivered: "Auftrag zugestellt",
      goodsReceived: "Ware im Depot",
      created: "Auftrag erfasst",
      deliveryStopped: "Ausführung unterbrochen",
      inDelivery: "Auftrag unterwegs",
      cancelled: "Auftrag storniert",
      problemInDepot: "Ware unvollständig"
    }
  },
  appointment: {
    titel: "Terminvereinbarung",
    yourAppointment: "Ihr Wunschtermin",
    setWhen: "Folgender Termin wurde gebucht: ",
    change:
      "Sie können den vereinbarten Termin nicht einhalten und möchten ihn verschieben?",
    mon: "Montag, den",
    tue: "Dienstag, den",
    wed: "Mittwoch, den",
    thu: "Donnerstag, den",
    fri: "Freitag, den",
    sat: "Samstag, den",
    sun: "Sonntag, den",
    date: "Datum",
    recommendation: "Empfehlung",
    noAppointments: "keine Termine verfügbar",
    defaultDate: "11.04.2020",
    defaultTime: "12-14 Uhr",
    changeAppointment: "Wunschtermin Bearbeiten",
    agreedDeliveryDate:
      "Sie haben folgenden Termin für die Lieferung Ihrer Sendung vereinbart:",
    deliveryTime: "Lieferzeit",
    appointmentNotPossible: "Terminvereinbarung nicht möglich",
    onlineAppointmentImpossible:
      "Eine Terminvereinbarung ist Online leider nicht möglich.",
    serviceCenterWillContactYou:
      "Unser Servicecenter wird sich diesbezüglich zeitnah mit Ihnen in Verbindung setzen, um mit Ihnen zeitnah einen Liefertermin zu vereinbaren. Gerne können Sie uns weitere Angaben zu den Lieferbeschrankungen machen.",
    confirmServiceCenter:
      "Mit einem Klick auf „Bestätigen“ wird eine Information an unser Servicecenter gegeben und man wird sich zeitnah bei Ihnen melden.",
    voluntaryInformation: "Die folgenden Angaben sind freiwillig",
    restrictionOne: "Beschränkung 1",
    restrictionTwo: "Beschränkung 2",
    restrictionThree: "Beschränkung 3",
    moreAnnotations: "Haben Sie weitere Anmerkungen?",
    annotations: "Anmerkungen",
    yourMessage: "Ihre Nachricht",
    desiredDate: "Ihr Wunschtermin",
    bookDesiredDate: "Wunschtermin buchen",
    desiredDateAgreed: "Sie haben Ihren Wunschtermin vereinbart für den",
    changeDesiredDate:
      "Ihnen ist etwas dazwischen gekommen und Sie möchten Ihren Wunschtermin ändern?",
    furtherDeliveryInformation: "Weitere Lieferinformation",
    noteOnDelivery: "Was müssen wir bei der Lieferung beachten?",
    extraService: "Zusatzleistungen",
    deliveryInformation: "Lieferinformationen",
    appointmentImpossible:
      "Aufgrund von Lieferbeschränkungen ist eine Terminvereinbarung nicht möglich.Unser Servicecenter wird sich zeitnah mit Ihnen in Verbindung setzen, um einen Termin zu vereinbaren.",
    servicecenterInformed:
      "Unser Servicecenter wurde über die Lieferbeschränkungen informiert. Wir bearbeiten Ihren Auftrag so schnell wie möglich und melden uns zeitnah bei Ihnen.",
    appointmentSelection: "Terminauswahl",
    confirmAppointment: "Termin bestätigen",
    anyDeliveryRestrictions: "Liegen bei Ihnen Lieferbeschränkungen vor?",
    neededInformation:
      "Bevor Sie sich einen Termin aussuchen können, benötigen wir noch die folgenden Informationen: " +
      "Gibt es an Ihrer angegebenen Lieferadresse Hindernisse, die die Anfahrt erschweren?" +
      "Solche Hindernisse sind z.B.Fußgängerzonen, Baustellen, eingeschränkt befahrbare Gebiete(LKW- Verbotszone).",
    chooseAppointment: "Wählen Sie Ihren Wunschtermin",
    additionalServices: "Dürfen wir Ihnen weitere Zusatzleistungen anbieten?",
    additionalServiceDesiredDate: "Ihre gebuchte Zusatzleistung Wunschtermin",
    additionalService: "Zusatzleistung",
    note: "Bitte beachten Sie:",
    noteInformation:
      "Die kostenpflichtigen Zusatzleistungen sind bei Anlieferung bar zu entrichten.",
    loremIpsum:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    loremIpsumShort: "Lorem ipsum dolor amet",
    loremIpsumLong:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    costs: "Kosten",
    costsNote: "Die Lieferkosten sind bei Anlieferung bar zu entrichten.",
    deliveryCosts: "Lieferkosten",
    whichFloor: "In welches Stockwerk soll geliefert werden?",
    floor: "Stockwerk",
    liftAvailable: "Ist ein Lift vorhanden?",
    agbNote: "Ich habe die AGBs gelesen und verstanden",
    agbNoteShort: "AGBs gelesen und verstanden",
    pleaseNote:
      "Bitte beachten Sie: Falls Sie ein kostenpflichtiges Zeitfenster wählen, sind die für dieses Zeitfenster ausgewiesenen Zusatzkosten bei Anlieferung bar zu entrichten.",
    lift: "Lift",
    parkingAvailable: "Sind Parkmöglichkeiten vorhanden?",
    parking: "Parkmöglichkeit",
    bookDeliveryDate:
      "Kontrollieren Sie Ihre Eingaben und buchen Sie Ihren Wunschtermin",
    selectedAppointment: "Ihr ausgewählter Wunschtermin",
    deliveryTimeWindow: "Lieferzeitfenster",
    deliveryRestrictions: {
      titel: "Lieferbeschränkungen",
      query: "Liegen bei Ihnen Lieferbeschränkungen vor?",
      moreInfo:
        "Bevor Sie sich einen Termin aussuchen können, benötigen wir noch die folgenden Informationen: ",
      querySpecific:
        "Gibt es an Ihrer angegebenen Lieferadresse Hindernisse, die die Anfahrt erschweren? ",
      restrictionExamples:
        "Solche Hindernisse sind z. B. Fußgängerzonen, Baustellen, eingeschränkt befahrbare Gebiete (LKW-Verbotszone).",
      successInfo:
        "Sie haben angegeben, dass Lieferbeschränkungen den Zugang zu Ihrer Lieferadresse erschweren. Unser Service Center nimmt spätestens am nächsten Arbeitstag mit Ihnen Kontakt auf, um einen Termin mit Ihnen zu vereinbaren."
    },
    failure: {
      titel: "Termin nicht vereinbart",
      noRights:
        "Unser Customer Service wird sich so schnell wie möglich telefonisch bei Ihnen melden, um einen Termin mit Ihnen zu vereinbaren."
    },
    selection: {
      titel: "TERMINAUSWAHL",
      info: "WÄHLEN SIE IHREN WUNSCHTERMIN AUS",
      additionalInfo:
        "Bitte beachten: Die Übersicht enthält alle möglichen Liefertermine. Auch unser Customer Service Team kann Ihnen keine früheren Termine anbieten.",
      confirmSelection: "AUSWAHL BESTÄTIGEN"
    },
    confirmation: {
      title: "TERMIN BESTÄTIGEN",
      selectedAppointment: "IHR AUSGEWÄHLTER WUNSCHTERMIN",
      deliveryDate: "Liefertermin",
      deliveryTimeWindow: "Lieferzeitfenster",
      deliveryCosts: "Kosten",
      gtcs: "AGBs",
      gtcsUnderstood: " gelesen und verstanden",
      back: "ZURÜCK",
      confirm: "WUNSCHTERMIN BESTÄTIGEN",
      unknownPrice:
        "AUF ANFRAGE - UNSERE KOLLEGEN IM SERVICE CENTER MELDEN SICH ZEITNAH TELEFONISCH BEI IHNEN",
      backEndError:
        "Terminvereinbarung leider nicht möglich. Bitte versuchen Sie es später erneut.",
      additionalServicesError: "Service konnte nicht gebucht werden.",
      additionalServices: "Ihre Gebuchten Zusatzleistungen",
      TotalCostsInfo: "Bar zu zahlender Betrag bei Anlieferung"
    },
    terms: {
      title: "Terminvereinbarung - AGBs"
    },
    summary: {
      title: "Vielen Dank",
      description: "Folgender Termin wurde gebucht:",
      emission: {
        description:
          "EMISSIONEN KOMPENSIEREN!\r\n Es ist überraschend, wie viel klimawirksame CO2-​Emissionen beim Fliegen, Autofahren, Wohnen, Arbeiten oder Feiern entstehen.\r\n Auch eine Möbellieferung verursacht einen CO2-Ausstoß. Mit einer Spende in Höhe von ca. 6€ können Sie Ihre Lieferung ausgleichen und so einen freiwilligen Beitrag zum Klimaschutz leisten.",
        compensateButton: "Kompensieren via MyClimate"
      },
      enterInCalendarButton: "In Kalender Eintragen",
      icsFileName: "Liefertermin.ics",
      icsFileEmailBody:
        "Hallo {name},<br>Ihr Termin für Ihren {clientName}-Auftrag {orderNumber} steht fest.<br>" +
        "Das voraussichtliche Lieferzeitfenster wird am Vortag der Lieferung um ca. 15-16 Uhr auf 2 Stunden eingegrenzt.<br>" +
        "Diese und weitere Informationen rund um Ihre Lieferung können Sie auf Rhenusmydelivery.com/de einsehen.<br><br>" +
        '<a href="https://www.rhenusmydelivery.com/de">Jetzt Sendung verfolgen!</a><br><br>' +
        "Ihr Rhenus Home Delivery Team",
      icsFileEmailSubject:
        "Ihr {clientName}-Auftrag {orderNumber}: Terminbestätigung für {date}"
    },
    errorMessage: {
      titleNoAppointments:
        "Leider können wir Ihnen aktuell keine Termine anbieten",
      titleDeliveryRestriction:
        "Leider können wir Ihre Angabe aktuell nicht verarbeiten.",
      info: "Bitte versuchen Sie es später erneut oder wenden sich an das ",
      serviceCenter: "Service Center.",
      iCalendarBackend:
        "Die Generierung des Kalendereintrags ist leider nicht möglich."
    }
  },
  tracking: {
    BackToOverview: "Zurück zur Übersicht",
    Appointment: "Jetzt Liefertermin vereinbaren!",
    NotYetAvaiable: "nicht verfügbar",
    NoteTracking:
      "*Bitte beachten Sie, dass Ihnen erst am Vortag Ihres Auftragstermins ab 15 Uhr angezeigt werden kann, wann unsere Fahrer voraussichtlich bei Ihnen eintreffen werden",
    OrderTracking: "Sendungsverfolgung",
    OrderState: "Aktueller Status Ihrer Sendung",
    DeliveryDate: "Liefertermin",
    DeliveryTimeWindow: "Lieferzeitfenster",
    ETA: "Voraussichtliche Ankunft",
    Only: "Noch",
    StopPopUp: "Stopps bis zu Ihrer Lieferung!",
    Error:
      "Die geografische Sendungsverfolgung Ihrer Lieferung ist leider nicht möglich.",
    Questions: "Bei Fragen wenden Sie sich bitte an den Customer Service.",
    LiveTracking: "Lieferung live verfolgen",
    LiveTrackingDescription:
      "Sie können die Position und die voraussichtliche Ankunft des Teams hier live verfolgen."
  },
  error: {
    title: "Etwas ist schief gegangen",
    pageError:
      "Leider ist beim Aufrufen dieser Seite ein Fehler unterlaufen. Bitte nutzen Sie die Navigation um Ihren gewünschten Service auszuwählen oder rufen Sie unseren Service Center an."
  },
  additionalServices: {
    AdditionalServices: "Zusatzleistungen",
    AdditionalService: "Zusatzleistung",
    Info:
      "Hier können Sie weitere Services zu Ihrem Auftrag hinzubuchen und Ihre Lieferung noch reibungsloser gestalten.",
    OptionalAnswers: "Die folgenden Angaben sind freiwillig.",
    CostsWarning:
      "Bitte beachten: Anfallende Kosten sind bei Lieferung bar zu entrichten.",
    PackageTakeBack: "Verpackungsrücknahme",
    BookPackageTakeBack:
      "Sie benötigen die Verpackung Ihrer Ware nicht? Buchen Sie die Verpackungsentsorgung gleich mit! Wir kümmern uns um eine fachgerechte Entsorgung.",
    Costs: "Kosten",
    Back: "Zurück",
    Forward: "Weiter",
    unknownPrice: "auf Anfrage",
    VerpackungsEntsorgung: {
      name: "Verpackungsentsorgung",
      description:
        "Sie benötigen die Verpackung Ihrer Ware nicht? Buchen Sie die Verpackungsentsorgung gleich mit! Wir kümmern uns um eine fachgerechte Entsorgung."
    },
    VerpackungsEntsorgungVP: {
      name: "Verpackungsrücknahme",
      description:
        "Sie benötigen die Verpackung Ihrer Ware nicht? Buchen Sie die Verpackungsentsorgung gleich mit! Wir kümmern uns um eine fachgerechte Entsorgung."
    },
    ZusätzlicheLeistung: {
      name: "Zusätzliche Leistungen",
      description:
        "Viele Leistungen, wie ein Anruf des Fahrers vor Ankunft, sind bei uns bereits Standard. Abhängigkeit von Ihrem Vertragspartner bieten wir aber noch eine Reihe weiterer Zusatzleistungen an, wie zum Beispiel Montagen oder Entsorgungen von Altware (eins zu eins zur neuen Ware). Diese Leistungen sind dann mit einem Aufpreis verbunden. Unsere Kollegen im Service Center melden sich zeitnah telefonisch bei Ihnen, um die Services passgenau für Sie abzustimmen."
    }
  }
};
