import axios from "axios";
import AppConfig, { ApplicationConfiguration } from "@/assets/config";

export default class EnvironmentService {
  public static applicationConfiguration: ApplicationConfiguration;

  constructor() {
    EnvironmentService.applicationConfiguration = AppConfig;
  }

  public async loadCustomConfiguration() {
      const res = await axios.get<ApplicationConfiguration>("/assets/environment.json");
      EnvironmentService.applicationConfiguration = {...EnvironmentService.applicationConfiguration, ...res.data};
  }
}
