


























































import Vue from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import Calendar from "@/components/modules/Calendar.vue";
import Stepper from "@/components/modules/Stepper.vue";
import { Day } from "@/components/modules/types/Calendar";
import { getRouteName } from "@/routeConfig";

export default Vue.extend({
  components: {
    Calendar,
    Stepper,
  },
  data() {
    return {
      selectedAppointmentId: "",
      selectedDay: "",
    };
  },
  computed: {
    ...mapGetters(["getAppointments", "getOrder", "getStepperValue"]),
    ...mapState([
      "appointmentError"
    ]),
  },
  methods: {
    getContactRoute() {
      return  { name: getRouteName("contact") };
    },
    selectAppointment(appointmentId: string) {
      this.selectedAppointmentId =
        this.selectedAppointmentId === appointmentId ? "" : appointmentId;

      this.SET_SELECTED_APPOINTMENT(this.selectedAppointmentId);
    },
    selectDay(day: Day) {
      if (day.appointments.length) this.selectedDay = day.date;
    },
    selectRoute() {
      if (this.getStepperValue === 3) {
        this.$navigateTo("appointmentConfirmation");
        
      } else {
        this.$navigateTo("additionalServices");
      }
    },
    ...mapMutations(["SET_SELECTED_APPOINTMENT"]),
  },
});
