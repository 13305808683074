import { IOrderRequest } from "@/store/types/Order";
import RequestService from "@/services/RequestService";
import { ISelfSchedulingResponse } from "@/services/types/SelfSchedulingResponse";
import { AxiosResponse } from "axios";
import {featureFlags} from "@/store/feature-flag.store";

export class LoginService {
    public _requestService: RequestService;

    constructor() {
        this._requestService = new RequestService();
    }

    public async LoginByOrderNumberAndPostalCode(
        payload: IOrderRequest
    ): Promise<any> {
        if (!featureFlags.BOOKING_RMD_USECLOUDENDPOINTS) {
            return new Promise(resolve =>
                resolve({
                    internalOrderId: "",
                    loginSuccessful: false
                } as ISelfSchedulingResponse)
            );
        }
        return payload.recaptchaToken("login").then((token: string) =>
            this._requestService
                .getRequest(
                    `SelfScheduling/Order/OrderNumber/${payload.externalOrderNumber}/PostalCode/${payload.postcode}`,
                    {},
                    token,
                    featureFlags.BOOKING_RMD_USECLOUDENDPOINTS
                )
                .then((response: AxiosResponse<ISelfSchedulingResponse>) => {
                    if (response.data.loginSuccessful) {
                        return {
                            internalOrderId: response.data.internalOrderId,
                            loginSuccessful: response.data.loginSuccessful
                        } as ISelfSchedulingResponse;
                    }

                    return {
                        internalOrderId: "",
                        loginSuccessful: false
                    } as ISelfSchedulingResponse;
                })
        );
    }

    public async LoginByOrderNumberAndHash(
        payload: IOrderRequest
    ): Promise<any> {
        if (!featureFlags.BOOKING_RMD_USECLOUDENDPOINTS) {
            return new Promise(resolve =>
                resolve({
                    internalOrderId: "",
                    loginSuccessful: false
                } as ISelfSchedulingResponse)
            );
        }

        return payload.recaptchaToken("login").then((token: string) =>
            this._requestService
                .getRequest(
                    `SelfScheduling/Order/OrderNumber/${payload.externalOrderNumber}/Hash/${payload.hash}`,
                    {},
                    token,
                    featureFlags.BOOKING_RMD_USECLOUDENDPOINTS
                )
                .then((response: AxiosResponse<ISelfSchedulingResponse>) => {
                    if (response.data.loginSuccessful) {
                        return {
                            internalOrderId: response.data.internalOrderId,
                            loginSuccessful: response.data.loginSuccessful
                        } as ISelfSchedulingResponse;
                    }

                    return {
                        internalOrderId: "",
                        loginSuccessful: false
                    } as ISelfSchedulingResponse;
                })
        );
    }
}

export const loginService = new LoginService();
