













































import Vue from "vue";
import {mapState, mapActions} from "vuex";
import SeasonHelper from "@/helpers/SeasonHelper";
import {getLongLocaleCodeMap} from '@/i18n/index';
import EnvironmentService from "@/services/EnvironmentService";
declare let H: any;

let HImport: any;
if (process.env.NODE_ENV !== 'test') {
    HImport = import("@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js");
}

export default Vue.extend({
    data() {
        return {
            platform: {} as any,
            map: {} as any,
            markers: {} as any,
            mapEvents: {} as any,
            mapBehavior: {} as any,
            clientLat: 0,
            clientLng: 0,
            truckLng: 0,
            truckLat: 0,
            closed: false,
            ui: {} as any,
        };
    },
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(["service", "clientLatLng"]),
    },

    async mounted() {

        // init platform object
        H = (await HImport).default;
        this.platform = new H.service.Platform({
            apikey: EnvironmentService.applicationConfiguration.hereApiKey,
        });
        // construct group of markers
        this.markers = new H.map.Group();

        // init coordinates
        this.truckLng = parseFloat(this.service.actualInformationTo.gpsLongitude);
        this.truckLat = parseFloat(this.service.actualInformationTo.gpsLatitude);

        this.clientLat = parseFloat(
            this.service.order.address.coordinates.latitude
        );
        this.clientLng = parseFloat(
            this.service.order.address.coordinates.longitude
        );
        const engineType = H.Map.EngineType['HARP'];
        const defaultLayers = this.platform.createDefaultLayers({engineType, lg: this.$i18n.locale}) as any;
        // init map object
        this.map = new H.Map(
            this.$refs.hereMap as HTMLElement,
            defaultLayers.vector.normal.map, {
                engineType: engineType,
                zoom: 11,
                center: {
                    lng: this.clientLng,
                    lat: this.clientLat
                },
            }
        );

        this.map.addObject(this.markers);
        this.mapEvents = new H.mapevents.MapEvents(this.map);
        this.mapBehavior = new H.mapevents.Behavior(this.mapEvents);
        this.ui = H.ui.UI.createDefault(
            this.map,
            defaultLayers,
            getLongLocaleCodeMap(this.$i18n.locale)
        );
        const promiseUserLocation = this.retrieveUserLocation();
        const promiseFleetLocation = this.retrieveFleetLocation();
        const promisesLocations = [promiseUserLocation, promiseFleetLocation];
        Promise.allSettled(promisesLocations).then(() => this.focusMarkers());
    },

    methods: {
        retrieveUserLocation() {
            const season = SeasonHelper.getSeason(new Date());
            const domIcon = new H.map.DomIcon(this.$refs["home" + season] as Element);

            // Client marker
            return new Promise<void>((resolve) => {
                const clientMarker = new H.map.DomMarker(
                    {lat: this.clientLat, lng: this.clientLng},
                    {icon: domIcon}
                );
                this.markers.addObject(clientMarker);
                resolve();
            });
        },

        retrieveFleetLocation() {
            const season = SeasonHelper.getSeason(new Date());

            const domIcon = new H.map.DomIcon(
                this.$refs["fleet" + season] as Element
            );

            // Fleet marker
            return new Promise<void>((resolve) => {
                const truckMarker = new H.map.DomMarker(
                    {lat: this.truckLat, lng: this.truckLng},
                    {icon: domIcon}
                );
                truckMarker.setData(
                    '<div class="H_ib_tail2"></div><p class="Popupfont">' +
                    this.$t("tracking.Only") +
                    " " +
                    this.service.actualInformationTo.remainingOrders +
                    " " +
                    this.$t("tracking.StopPopUp") +
                    "</p>"
                );
                const bubbleInitial = new H.ui.InfoBubble(
                    {lat: this.truckLat, lng: this.truckLng},
                    {
                        content: truckMarker.getData(),
                    }
                );
                this.ui.addBubble(bubbleInitial);
                bubbleInitial.open();

                truckMarker.addEventListener("tap", (event: any) => {
                    const et = event.target as unknown as any;
                    const bubble = new H.ui.InfoBubble(et.getGeometry() as any, {
                        content: et.getData(),
                    });
                    this.ui.addBubble(bubble);
                });
                this.markers.addObject(truckMarker);
                resolve();
            });
        },

        focusMarkers() {
            // get geo bounding box for the group and set it to the map
            this.map.getViewModel().setLookAtData({
                bounds: this.markers.getBoundingBox(),
            });
        },
        ...mapActions(["getGeoCoordinatesByAddress"]),
    },
});
