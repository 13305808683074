import Vue from 'vue';
import Vuex from 'vuex';
import { IState, IFundamentalData, IStateHistory } from '@/store/types/IState';
import { IOrder, IOrderRequest, IOrderSchedulability, IRecaptchaTokenCallback } from '@/store/types/Order';
import { IRequestToken } from '@/store/types/Order';
import { AxiosResponse } from 'axios';
import { IAppointmentsRequest, IAppointmentTo, ISetAppointmentRequest, IIcsFileData, IDeliveryRestrictionRequest } from '@/store/types/Appointment';
import { IDocumentsRequest, IDocumentRequest, IDocumentData, IDocument } from '@/store/types/Document';
import { Appointment } from '@/components/modules/types/Calendar';
import moment from 'moment';
import { IGeoAddress } from '@/store/types/Geo';
import { ILatLng } from '@/store/types/Order';
import { IBookableService, IBookableServicesApi, IBookServicesApi, IBookedService, IPrice } from '@/store/types/Service';
import '@/extensions/StringExtensions.ts';
import i18n, { getCurrencyCode} from '@/i18n/index';
import RequestService from '@/services/RequestService';
import CalendarHelper from '@/helpers/CalendarHelper';
import BookableServiceFilter from '@/helpers/BookableServiceFilter';
import ViewportHelper from '@/helpers/ViewportHelper';
import VuexPersist from 'vuex-persist';
import { loginService } from '@/services/LoginService';
import { ISelfSchedulingResponse } from '@/services/types/SelfSchedulingResponse';
import { orderService } from '@/services/OrderService';
import {featureFlags} from "@/store/feature-flag.store";
import { deliveryRestrictionService } from '@/services/DeliveryRestrictionService';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex-state',
  storage: window.sessionStorage,  
})
const requestService = new RequestService();
const calendarHelper = new CalendarHelper();
const bookableServiceFilter = new BookableServiceFilter();
const viewportHelper = new ViewportHelper();

const apiEndPoints = {
  /*   Getters   */
  getIcsFile: 'api/appointment/getIcsFile',
  getOrder: 'api/order',
  getCanScheduleOrder: "api/order/schedulability",
  getDocuments: 'api/documents',
  getAppointments: 'api/appointment',
  getGeoCoordinates: 'api/geo/getGeoCodeViaAddress',
  getBookableServices: 'api/services',
  /*   Setters   */
  setRestriction: 'api/appointment/deliveryRestriction',
  setAppointment: 'api/appointment/setAppointment',
  /*   Misc      */
  sendContactEmail: 'api/contact',
  bookServices: 'api/services/bookServices'
};
const stepper = {
  withAdditionalServices: 4,
  withoutAdditionalServices: 3
};

function setNotification(service: IFundamentalData) {
  const order = service.order;
  const orderState = order.orderState;

  const cancelledStates = ['Storno','StornoBeiAbsortierung', 'StornoVorAbsortierung', 'StornoZl'];
  const isCancelled = service.stateHistoryList ? service.stateHistoryList.some((item: IStateHistory) => item.orderState !== null && cancelledStates.includes(item.orderState)) : false;
  
  // Order created
  let descriptionKey = 'chooseAppointment';
 
  if (isCancelled) {
    descriptionKey = 'orderCanceled';
  }
    // Completed
    else if (order.isCompleted) {
    descriptionKey = 'orderComplete';
    // Canceled
  } else if (orderState === 'Storno' || orderState === 'StornoZL') {    
    descriptionKey = 'orderCanceled';
    // Unsuccessful delivery
  } else if (orderState === 'NA') {
    descriptionKey = 'deliveryUnsuccessful';
    // Delayed
  } else if (order.deliveryDelayed) {
    descriptionKey = 'deliveryDelayed';
    // Time window narrowed
  } else if (order.timeWindowNarrowed) {
    descriptionKey = 'timeWindowNarrowed';
    // Delivery Enroute
  } else if (orderState === 'UnterwegsZumKunden') {
    descriptionKey = 'deliveryEnRoute';
    // Date changed
  } else if (order.fixedDateChanged) {
    descriptionKey = 'fixedDateChanged';
    // Date set
  } else if (service.hasAppointment) {
    descriptionKey = 'fixedDate';
  } 

  return i18n.t('notificationBox.description.' + descriptionKey) as string;
}

function translateOrderState(tmsOrderStateDescription: string | null): string | null {
  switch(tmsOrderStateDescription) {
    case 'Auftrag zugestellt':
      return i18n.t('order.orderState.delivered').toString();
    case 'Ware erhalten':
      return i18n.t('order.orderState.goodsReceived').toString();
    case 'Auftrag erfasst':
      return i18n.t('order.orderState.created').toString();
    case 'Ausfuehrung unterbrochen':
      return i18n.t('order.orderState.deliveryStopped').toString();
    case 'Auftrag unterwegs':
      return i18n.t('order.orderState.inDelivery').toString();
    case 'Auftrag storniert':
      return i18n.t('order.orderState.cancelled').toString();
    case 'Problem im Depot':
      return i18n.t('order.orderState.problemInDepot').toString();
      default:
        return tmsOrderStateDescription;
  }
}

const store = new Vuex.Store({
  state: {
    language: 'de',
    isLoggedIn: false,
    blockScroll: false,
    isStartup: true,
    hasNews: true,
    notificationDescription: '',
    service: {
      order: {} as IOrder,
      dayAmountForAppointment: 0,
      canMakeAppointment: false,
      hasAppointment: false,
      clientMapping: {},
      showCompensateEmissionsForClients: [],
      loginSuccessful: false
    } as IFundamentalData,
    customerAppointments: [],
    bookableServices: [],
    bookedServices: [],
    deliveryRestriction: false,
    selectedAppointmentId: '',
    appointmentError: false,
    clientLatLng: {} as ILatLng,
    overlappingTimes: 0,
    minStickyBottom: 45,
    stickyBottom: 0,
    customErrorMessage: '',
    lastRequestDate: null,
    internalOrderId: ''
  } as IState,

  getters: {
    getOrder(state: IState) {
      const orderStateDescription = state.service.order.orderStateDescription;
      state.service.order.orderStateDescription = translateOrderState(orderStateDescription);
      return state.service.order;
    },
    getAppointments(state: IState) {
      return state.customerAppointments;
    },
    getOverlappingTimes(state: IState) {
      return state.overlappingTimes;
    },
    getService(state: IState) {
      return state.service;
    },
    getExternalOrderNumber(state: IState) {
      return state.service.order.externalOrderNumber;
    },
    getAddress(state: IState) {
      return state.service.order.address;
    },
    getMakingOrShowingAppointment(state: IState) {
     return state.service.canMakeAppointment || state.service.hasAppointment;
    },
    getCanMakeAppointment(state: IState) {
      return state.service.canMakeAppointment;
    },
    getHasAppointment(state: IState) {
      return state.service.hasAppointment;
    },
    getDeliveryRestriction(state: IState) {
      return state.deliveryRestriction;
    },
    getSelectedAppointment(state: IState) {
      if (!state.selectedAppointmentId) {
        return null;
      }

      const selectedAppointment =
        state.customerAppointments.filter((x: { id: string }) => x.id === state.selectedAppointmentId)[0];

      if (!selectedAppointment.timeslot) {
        selectedAppointment.timeslot = moment(selectedAppointment.start).format('HH:mm') +
          ' - ' +
          moment(selectedAppointment.end).format('HH:mm');

        // ENDCP-191 - Don't show 'Hodiny' in timeslot on confirmation page
        if (i18n.locale !== "cz")
          selectedAppointment.timeslot += ' ' + i18n.t('general.clock');
      }

      return selectedAppointment;
    },
    getEarliestAppointment(state: IState) {
      if (!state.customerAppointments || !state.customerAppointments.length) {
        return null;
      }

      return state.customerAppointments[0];
    },
    getStepperValue(state: IState) {
      if (state.bookableServices.length > 0) {
        return stepper.withAdditionalServices;
      } else {
        return stepper.withoutAdditionalServices;
      }
    },
    getLogoName(state: IState) {
      try {
        return require(`@/assets/icons/clientlogos/${state.service.order.clientKey}.svg`);
      } catch (error) {
        return "";
      }
    },
    isIkeaClient(state: IState, getters: any) {
      if (Object.keys(state.service.clientMapping).length !== 0) {
        if (getters.getMappedClientName === state.service.clientMapping['Ikea'].name) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    getMappedClientName(state: IState) {
      for (const key in state.service.clientMapping) {
        const keyList = state.service.clientMapping[key].keyList;
        const clientKey = state.service.order.clientKey;
        
        if (keyList != null &&
          clientKey != null &&
          keyList.includes(clientKey)) {
          return state.service.clientMapping[key].name;
        }
      }
      return state.service.order.organizationUnitDescription;
    },
    showCompensateEmissions(state: IState) {
      if (!state.service.order.clientKey || state.language !== 'de') {
        return false;
      }
      return state.service.showCompensateEmissionsForClients.includes(state.service.order.clientKey);
    },
    allowTracking(){
      return (i18n.locale === "de" || i18n.locale === "pl" || i18n.locale === "cz");
    }
  },
  mutations: {
    LOCALIZATION(state: IState, language: string) {
      state.language = language;
    },
    LOGIN(state: IState) {
      state.isLoggedIn = true;
      state.isStartup = true;
      state.hasNews = true;
      state.notificationDescription = setNotification(state.service);
      state.lastRequestDate = new Date();
    },
    LOGOUT(state: IState) {
      state.isLoggedIn = false;
      state.appointmentError = false;
      state.lastRequestDate = null;
      featureFlags.setClientKey('');
      if(window.$zoho) {
        window.$zoho.salesiq.floatbutton.visible('show');
      }
    },
    NEW_REQUEST(state: IState) {
      state.lastRequestDate = new Date();
    },
    END_STARTUP(state: IState) {
      state.isStartup = false;
    },
    DISABLE_NEWS(state: IState) {
      state.hasNews = false;
    },
    SET_ORDER(state: IState, service: IFundamentalData) {
      state.service = service;
      featureFlags.setClientKey(service.order.clientKey!);
    },
    SET_ORDERSCHEDULABILITY(state: IState, schedulability: IOrderSchedulability) {
      state.service.canMakeAppointment = schedulability.canMakeAppointment;      
      state.service.dayAmountForAppointment = schedulability.dayAmountForAppointment;      
    },
    SET_CLIENTLNGLAT(state: IState, clientLngLat: ILatLng) {
      state.clientLatLng = clientLngLat;
    },
    SET_BOOKEDSERVICES(state: IState, bookedServices: IBookableService[]) {
      state.bookedServices = bookedServices;
    },
    BLOCK_SCROLL(state: IState, blockscroll: boolean) {
      const body = document.querySelector('body');
      if (body)
        blockscroll ? body.style.overflowY = 'hidden' : body.style.removeProperty('overflow-y');

      state.blockScroll = blockscroll;
    },
    SET_APPOINTMENTS(state: IState, appointments: Appointment[]) {
      state.customerAppointments = appointments;
    },
    SET_ALTERNATELANE(state: IState) {
      let max = 7;
      const appointments = state.customerAppointments;
      if (appointments.length > 1) {
        const selectedWeek = appointments[0].date;
        const appointmentsWithinWeek = calendarHelper.getMaxAlternateLane(appointments, appointments.length, 7, selectedWeek);
        const appointmentsInWeek = appointmentsWithinWeek.map(u => u.alternateLane);
        max = Math.max(...appointmentsInWeek) + 1;
      }
      state.overlappingTimes = max;
    },
    SET_BOOKABLESERVICES(state: IState, bookableServices: IBookableService[]) {
      state.bookableServices = bookableServiceFilter.getFilteredBookableServicesPackagingDisposal(bookableServices, state.service.order);
      state.bookableServices = bookableServiceFilter.getFilteredBookableServicesAdditionalService(state.bookableServices,state.service.order);
    },
    SET_DELIVERY_RESTRICTION(state: IState, value: boolean) {
      state.deliveryRestriction = value;
    },
    SET_SELECTED_APPOINTMENT(state: IState, value: string) {
      state.selectedAppointmentId = value;
    },
    SET_APPOINTMENT_ERROR(state: IState, value: boolean) {
      state.appointmentError = value;
    },
    SET_STICKYBOTTOM(state: IState, value?: number) {
      state.stickyBottom = value || state.minStickyBottom;
    },
    SET_CUSTOM_ERROR_MESSAGE(state: IState, value?: string) {
      state.customErrorMessage = value || '';
    },
    SET_INTERNALORDERID(state: IState, value?: string)
    {
      state.internalOrderId = value || '';
    }
  },

  actions: {
    requestCanMakeAppointment({ commit }, payload: IRequestToken) {
      const order = store.state.service.order as IOrder;
      const address = order.address;
      const apiInput = {
        externalOrderNumber: order.externalOrderNumber,
        postcode: address.zipCode
      };

      return requestService.postRequest(apiEndPoints.getCanScheduleOrder, apiInput, payload.recaptchaToken)
        .then((response: AxiosResponse<IOrderSchedulability>) => {
          commit('SET_ORDERSCHEDULABILITY', response.data);
          return null;
        });
    },


    requestOrderDetails({ commit }, payload: IOrderRequest) {

      if (!payload.encryptionLoginType) {
        return loginService.LoginByOrderNumberAndPostalCode(payload).then((loginResponse: ISelfSchedulingResponse) => {

          if(loginResponse.loginSuccessful)
            commit('SET_INTERNALORDERID', loginResponse.internalOrderId);

          return orderService.GetOrderDetails(payload, apiEndPoints.getOrder).then(response => 
            {
              if(!response.result)
                return false;
  
              if(response.locale!='')
                commit('LOCALIZATION', response.locale);
  
              commit('SET_ORDER', response.data);
              return true;
  
            });
        });
      }
      
      return loginService.LoginByOrderNumberAndHash(payload).then((loginResponse: ISelfSchedulingResponse) => {
        if (loginResponse.loginSuccessful)
          commit('SET_INTERNALORDERID', loginResponse.internalOrderId);
          
        return orderService.GetOrderDetails(payload, apiEndPoints.getOrder).then(response => 
          { 
            if(!response.result)
              return false;

            if(response.locale!='')
              commit('LOCALIZATION', response.locale);

            commit('SET_ORDER', response.data);
            return true;

          });
      }).catch((response) => {
        console.log(response);
      });
    },

    requestDocuments(_, payload: IDocumentsRequest): IDocument[] {
      return requestService.getRequest(apiEndPoints.getDocuments, payload, payload.recaptchaToken,false)
        .then((response: AxiosResponse<IDocumentData>) => {
          if (response.status !== 200) {
              return [];
            }
          return response.data.documents;
        });
    },

    requestDocument(_, payload: IDocumentRequest) {
      return requestService.getDirectRequest(payload.url, payload.recaptchaToken, payload.responseType);
    },

    requestIcsFile(_, payload: IRequestToken) {
      const order = store.state.service.order as IOrder;
      const address = order.address;
      const appointment = store.getters.getSelectedAppointment as Appointment;

      const date = calendarHelper.getFormattedDateForDisplay(appointment.start);
      const emailBody = i18n.t('appointment.summary.icsFileEmailBody', { name: `${address.firstName} ${address.surName}`, clientName: order.clientName, orderNumber: order.externalOrderNumber });
      const emailSubject = i18n.t('appointment.summary.icsFileEmailSubject', { clientName: order.clientName, orderNumber: order.externalOrderNumber, date })
      const icsFileData = {
        emailBody: emailBody,
        emailSubject: emailSubject,
        fromTime: calendarHelper.getFormattedDateTime(appointment.start),
        toTime: calendarHelper.getFormattedDateTime(appointment.end),
        address: `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.location}`
      } as IIcsFileData;

      return requestService.postRequest(apiEndPoints.getIcsFile, icsFileData, payload.recaptchaToken);
    },

    requestCustomerAppointments({ commit }, payload: IRequestToken) {
      const order = store.state.service.order;
      const appointmentParameters = {
        orderNumberUnique: order.internalOrderNumber,
        clientIdentifier: order.clientKey,
        countryCode: order.address.country === 'D' ? 'DE' : order.address.country,
        zipCode: order.address.zipCode,
        weight: order.weight,
        volume: order.volume,
        dayAmountForAppointment: store.state.service.dayAmountForAppointment,
        countryCodeFormat: order.address.country === 'D' ? 'DE' : order.address.country,
        backwards: false,
        orderState: order.orderState,
        createDate: order.createDate,
        locationEinsteuerung: order.locationEinsteuerung,
        locationCommissioning: order.locationCommissioning,
        location: order.location,
        extraOrder: order.extraOrder,
      } as IAppointmentsRequest;

      return requestService.postRequest(apiEndPoints.getAppointments, appointmentParameters, payload.recaptchaToken)
        .then((response: AxiosResponse) => {
          //TODO ENDCP-58 fix currency to general solution in avisierungsservice
          const currency = getCurrencyCode(appointmentParameters.countryCode);

          const appointments = response.data.map((appointment: IAppointmentTo) => {
            const appointmentMapped = {
              id: appointment.id,
              start: appointment.fromTime,
              end: appointment.toTime,
              timeslot: '',
              date: calendarHelper.getFormattedDate(appointment.fromTime),
              price: appointment.price,
              isRecommendation: appointment.isRecommended,
              currency: currency,
              priceId: appointment.priceId
            } as Appointment;

            return appointmentMapped;
          }) as Appointment[];

          commit('SET_APPOINTMENTS', appointments);
          // Mutations are synchronous. The commit on SET_ALTERNATELANE will use the updated appointments (from SET_APPOINTMENTS).
          commit('SET_ALTERNATELANE');
          commit('SET_APPOINTMENT_ERROR', false);
          return null;
        })
        .catch(() => {
          //handling error
          commit('SET_APPOINTMENT_ERROR', true);
        });
    },

    requestBookableServices({ commit }, payload: IRequestToken) {
      const data = {
        externalOrderNumber: store.state.service.order.externalOrderNumber
      } as IBookableServicesApi;

      return requestService.getRequest(apiEndPoints.getBookableServices, data, payload.recaptchaToken,false)
        .then((response: AxiosResponse<IBookableService[]>) => {
          commit('SET_BOOKABLESERVICES', response.data);
          return null;
        });
    },

    bookServices(_, payload: IRequestToken) {
      const curDateString = new Date(Date.now()).toUTCString();
      const data = {
        clientKey: this.state.service.order.clientKey,
        externalOrderNumber: this.state.service.order.externalOrderNumber,
        language: i18n.locale,
        articlesOnOrder: this.state.service.order.deliveryArticleCount,
        services: this.state.bookedServices.map((service) => {
          return {
            serviceName: service.key,
            servicePrice: {
              amount: service.sumPrice,
              currency: store.getters.getSelectedAppointment.currency
            } as IPrice,
            creationDateTime: calendarHelper.getFormattedDateTime(curDateString)
          } as IBookedService
        })
      } as IBookServicesApi;

      return requestService.postRequest(apiEndPoints.bookServices, data, payload.recaptchaToken);
    },

    setCustomerAppointment(_, payload: IRequestToken) {

      const appointment = store.getters.getSelectedAppointment as Appointment;

      const confirmedAppointment = {
        appointment: appointment.date,
        fromTime: moment(appointment.start).format('HH:mm'),
        toTime: moment(appointment.end).format('HH:mm'),
        costs: appointment.price.toString(),
        currency: appointment.currency,
        internalOrderNumber: store.state.service.order.internalOrderNumber,
        earliestAppointment: store.getters.getEarliestAppointment.date,
        priceId: appointment.priceId,
        externalOrderNumber: store.state.service.order.externalOrderNumber,
        clientKey: store.state.service.order.clientKey
      } as ISetAppointmentRequest;

      return requestService.postRequest(apiEndPoints.setAppointment, confirmedAppointment, payload.recaptchaToken)
        .then((response: AxiosResponse) => {
          return response.data;
        });
    },

    async reloadOrderDetails(_, payload: IRequestToken) {
      const order = store.state.service.order;

      const request = {
        externalOrderNumber: order.externalOrderNumber,
        postcode: order.address.zipCode,
        recaptchaToken: payload.recaptchaToken as unknown as Function
      } as IOrderRequest;

      await store.dispatch('requestOrderDetails', request)
        .then(() => {
          // Reset news
          store.state.hasNews = true;
        });
    },

    sendContactEmail(_, payload: IContactRequest) {
      return requestService.postRequest(apiEndPoints.sendContactEmail, payload, payload.recaptchaToken);
    },

    setDeliveryRestriction({ commit }, payload: IRequestToken) {
      const param = {
        internalOrderNumber: store.state.service.order.internalOrderNumber,
        externalOrderNumber: store.state.service.order.externalOrderNumber,
        internalOrderId: store.state.internalOrderId,
        clientKey: store.state.service.order.clientKey,
      } as IDeliveryRestrictionRequest;

      return requestService.postRequest(apiEndPoints.setRestriction, param, payload.recaptchaToken)
        .then((response: AxiosResponse) => {
          commit('SET_APPOINTMENT_ERROR', false);
          return response.data;
        })
        .catch(() => {
          //handling error
          commit('SET_APPOINTMENT_ERROR', true);
        });
    },

    async setDeliveryRestrictionByBookingService({ commit }, payload: IRecaptchaTokenCallback) {
      const param = {
        internalOrderNumber: store.state.service.order.internalOrderNumber,
        externalOrderNumber: store.state.service.order.externalOrderNumber,
        clientKey: store.state.service.order.clientKey,
        internalOrderId: store.state.internalOrderId,
        recaptcha: payload.recaptchaToken
      } as IDeliveryRestrictionRequest;

      try {
        const response = await deliveryRestrictionService.SetDeliveryRestriction(param);
        commit('SET_APPOINTMENT_ERROR', false);
        return response.data;
      } catch {
        //handling error
        commit('SET_APPOINTMENT_ERROR', true);
      }
    },

    getGeoCoordinatesByAddress({ commit }) {
      const param = {
        street: store.state.service.order.address.street,
        houseNumber: store.state.service.order.address.houseNumber,
        postCode: store.state.service.order.address.zipCode,
        city: store.state.service.order.address.location
      } as IGeoAddress;

      return requestService.postRequest(apiEndPoints.getGeoCoordinates, param, '')
        .then((response: AxiosResponse<ILatLng>) => {
          commit('SET_CLIENTLNGLAT', response.data);
          return null;
        });
    },

    initStickyBottom({ commit }) {
      commit('SET_STICKYBOTTOM');
    },

    updateStickyBottom({ commit }) {
      const stickyBottomIsMin = store.state.stickyBottom == store.state.minStickyBottom;

      if (window.innerWidth <= 800) {
        // make sure that the sticky banner is reset to the correct bottom, if previously changed in desktop view
        if (!stickyBottomIsMin)
          commit('SET_STICKYBOTTOM');
      } else {
        const visibleFooterHeight = viewportHelper.getVisibleFooterHeight();

        if (visibleFooterHeight) {
          const newBottom = visibleFooterHeight + 10;
          if (newBottom > store.state.minStickyBottom)
            commit('SET_STICKYBOTTOM', newBottom);

        } else if (!stickyBottomIsMin) {
          commit('SET_STICKYBOTTOM');
        }
      }
    },

    setNewRequest({ commit }) {
      commit('NEW_REQUEST');
    }
  },
  plugins: [vuexLocalStorage.plugin]
});

export default store;
