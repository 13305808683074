





















































































































































































































































































































































































































  import Vue from "vue";
  import HeroText from "@/components/modules/HeroText.vue";
  import HighlightStrip from "@/components/modules/HighlightStrip.vue";
  import StickyBanner from "@/components/modules/StickyBanner.vue";
  import Card from "@/components/modules/Card.vue";
  import { IOrderRequest } from "@/store/types/Order";
  import { IRequestToken } from "@/store/types/Order";
  import {
    IDocumentsRequest,
    IDocument,
    IDocumentRequest,
  } from "@/store/types/Document";
  import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
  import queryString from "query-string";
  import { AxiosResponse } from "axios";
  import "@/extensions/StringExtensions.ts";
  import ViewportHelper from "@/helpers/ViewportHelper";
  import { getRouteName } from "@/routeConfig";
  import i18n from '@/i18n/index';
  import EnvironmentService from '@/services/EnvironmentService';
import { IStateHistory } from "@/store/types/IState";
  
  const viewportHelper = new ViewportHelper();
  
  export default Vue.extend({
    components: {
      HeroText,
      HighlightStrip,
      Card,
      StickyBanner,
    },
    data() {
      return {
        isLoading: false,
        documents: [] as IDocument[],
        pageComplete: false,
        clientsExcludedFromTrustPilot: EnvironmentService.applicationConfiguration.clientsExcludedFromTrustPilot.split(','),
        touch: {
          initialX: null as null | number,
          initialY: null as null | number,
        },
        currentCard: 0,
        hideBanner: false,
      };
    },
    mounted() {
      this.initStickyBottom();
      this.requestOrderByQuery();
      // set initial slide
      this.currentCard = 0;
      const nextButton = document.querySelector(".button-right");
      const prevButton = document.querySelector(".button-left");
      const container = document.querySelector(".cards");
  
      if (container && nextButton && prevButton) {
        // eslint-disable-next-line
        container.addEventListener("touchstart", this.startTouch, false);
        // eslint-disable-next-line
        container.addEventListener("touchmove", this.moveTouch, false);
        // eslint-disable-next-line
        nextButton.addEventListener("click", () => {
          this.nextCard();
        });
        // eslint-disable-next-line
        prevButton.addEventListener("click", () => {
          this.prevCard();
        });
      }
    },
    computed: {
      ...mapState([
        "isLoggedIn",
        "service",
        "notificationDescription",
        "language",
        "stickyBottom",
      ]),
      ...mapGetters([
        "getMappedClientName",
        "isIkeaClient",
        "getOrder",
        "getLogoName",
        "allowTracking"
      ]),
      
    },
    created() {
      window.addEventListener("resize", this.resizing);
      window.addEventListener("scroll", this.scrolling);
    },
    destroyed() {
      window.removeEventListener("resize", this.resizing);
      window.removeEventListener("scroll", this.scrolling);
    },
    methods: {
      openLink() {
        window.open("http://cloud.cjm.cls-rhenus.com/rhdaktuelleinformationen");
      },
      getLinkToTracking: function () {
        return { name: getRouteName("tracking") };
      },
      getLinkToDeliveryRestrictions: function () {
        return { name: getRouteName("deliveryRestriction") };
      },
      startTouch: function (e: Event) {
        const touchEvent = e as TouchEvent;
        const startTouch = touchEvent.touches[0];
  
        this.touch.initialX = startTouch.clientX;
        this.touch.initialY = startTouch.clientY;
      },
      moveTouch: function (e: Event) {
        const touchEvent = e as TouchEvent;
        if (this.touch.initialX === null || this.touch.initialY === null) {
          return;
        }
  
        const currentX = touchEvent.touches[0].clientX;
        const currentY = touchEvent.touches[0].clientY;
  
        const diffX = this.touch.initialX - currentX;
        const diffY = this.touch.initialY - currentY;
  
        if (Math.abs(diffX) > Math.abs(diffY)) {
          // sliding horizontally
          if (diffX > 0) {
            // swiped left
            this.nextCard();
          } else {
            // swiped right
            this.prevCard();
          }
        }
        this.touch.initialX = null;
        this.touch.initialY = null;
        e.preventDefault();
      },
      nextCard() {
        // current slide becomes hidden
        document.querySelectorAll("#slider .slide")[this.currentCard].className =
          "card slide";
        // set the current slide as the next one
        this.currentCard =
          (this.currentCard + 1) %
          document.querySelectorAll("#slider .slide").length;
        // add the class showing to the slide to make it visible
        document.querySelectorAll("#slider .slide")[this.currentCard].className =
          "card slide showing";
      },
      prevCard() {
        //hide current slide
        document.querySelectorAll("#slider .slide")[this.currentCard].className =
          "card slide";
        // set the current slide as the previous one
        this.currentCard =
          (this.currentCard - 1) %
          document.querySelectorAll("#slider .slide").length;
  
        if (this.currentCard == -1) {
          this.currentCard =
            document.querySelectorAll("#slider .slide").length - 1;
        }
        document.querySelectorAll("#slider .slide")[this.currentCard].className =
          "card slide showing";
      },
      requestOrderByQuery() {
        const query = queryString.parse(window.location.search);
        const hasOwnPropertyO: boolean = Object.prototype.hasOwnProperty.call(
          query,
          "o"
        );
        const hasOwnPropertyM: boolean = Object.prototype.hasOwnProperty.call(
          query,
          "m"
        );
        if (hasOwnPropertyO && hasOwnPropertyM) {
          this.$recaptchaLoaded().then(() => {
            const requestQuery = {
                externalOrderNumber: query.o,
                hash: query.m,
                recaptchaToken: (arg: string)=> this.$recaptcha(arg),
                encryptionLoginType: true,
              } as IOrderRequest;
  
              // handle automatic request for order details
              this.requestOrderDetails(requestQuery)
                .then((loginSuccessful: boolean) => {
                  if (loginSuccessful) {
                    this.LOGIN();
                    this.requestDocumentsByOrderNumber();
                    this.bookableServices();
                  }
                  else {
                    this.$navigateTo("error");
                  }
                })
                .catch(() => {
                  this.$navigateTo("error");
                });
          });
        } else {
          // handle what happens if the user doesn't have the neccessary URL parameters on
          if (this.isLoggedIn) {
            this.requestDocumentsByOrderNumber();
            this.bookableServices();
          } else {
            this.$navigateTo("error");
          }
        }
      },
      bookableServices() {
        this.$recaptchaLoaded().then(() => 
        { 
          this.$recaptcha("bookable_services").then((token: string) => {
          const recaptcha = { recaptchaToken: token } as IRequestToken;
          this.requestBookableServices(recaptcha);
        })
      });
      },
      displayValuationLogo() {
        return i18n.locale !== "cz";
      },
      requestDocumentsByOrderNumber() {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("get_documents").then((token: string) => {
            const requestQuery = {
              externalOrderNumber: this.getOrder.externalOrderNumber,
              internalOrderNumber: this.getOrder.internalOrderNumber,
              recaptchaToken: token,
            } as IDocumentsRequest;
  
            this.requestDocuments(requestQuery)
              .then((response: IDocument[]) => {
                this.documents = response;
              })
              .catch(() => {})
              .finally(() => {
                this.pageComplete = true;
              });
          });
        });
      },
      openDocument(documentUri: string, documentType: string) {
        this.isLoading = true;
        this.$recaptcha("open_document").then((token: string) => {
          const requestQuery = {
            url: documentUri,
            responseType: "blob", //Force to receive data in a Blob Format
            recaptchaToken: token,
          } as IDocumentRequest;
  
          this.requestDocument(requestQuery)
            .then((response: AxiosResponse<BlobPart>) => {
              //Create a Blob from the PDF Stream
              if (response.status === 200) {
                this.isLoading = false;
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(response.data);
                link.download = `${documentType}.pdf`;
                link.click();              
              }
            })
            .catch(() => {
              this.isLoading = false;
              this.$navigateTo("error");
            });
        });
      },
      resizing() {
        this.scrolling();
      },
      scrolling() {
        const bannerHighlightVueComponent = this.$refs.bannerHighlight as Vue;
        if (!bannerHighlightVueComponent) {
          return;
        }
        const bannerHighlight = bannerHighlightVueComponent.$el as HTMLElement;
        this.hideBanner = viewportHelper.isInViewport(bannerHighlight, 20);
  
        if (this.hideBanner) return;
        this.updateStickyBottom();
      },
      isCancelled() {
        const cancelledStates = ['Storno','StornoBeiAbsortierung', 'StornoVorAbsortierung', 'StornoZl'];
     
        return this.service.stateHistoryList ? this.service.stateHistoryList.some((item: IStateHistory) => item.orderState !== null && cancelledStates.includes(item.orderState)) : false;
      },
      ...mapMutations(["LOGIN"]),
      ...mapActions([
        "requestOrderDetails",
        "requestDocuments",
        "requestDocument",
        "requestBookableServices",
        "initStickyBottom",
        "updateStickyBottom",
      ]),
    },
  });
  