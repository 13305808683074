import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import i18n from '@/i18n/index';
import EnvironmentService from './EnvironmentService';
 

export default class RequestService {
    private readonly get: string = 'GET';
    private readonly post: string = 'POST';

    private getConfig(token: string, responseType?: string): AxiosRequestConfig {
        let currentLocale = i18n.locale;
        if (currentLocale === 'cz') {
            currentLocale = 'cs';
        }
        const headers = {
            "RMD-APP-TOKEN": token,
            'RH-Locale': currentLocale
        };

        const config = {
            headers: headers,
            responseType: responseType as ResponseType //| undefined
        } as AxiosRequestConfig;

        return config;
    }

    private callAxios(url: string, config: AxiosRequestConfig, method: string, parameters?: object) {
        let promise: any;

        if (method === this.get) {
            config.params = parameters;
            promise = axios.get(url, config);
        } else if (method === this.post) {
            promise = axios.post(url, parameters, config);
        } else
            promise = null;

        return promise;
    }

    private handleRequest(endpoint: string, parameters: object, token: string, method: string) {
        const url = EnvironmentService.applicationConfiguration.baseUrl + endpoint;
        const config = this.getConfig(token);
        return this.callAxios(url, config, method, parameters);
    }

    public getDirectRequest(url: string, token: string, responseType?: string) {
        const config = this.getConfig(token, responseType);

        return this.callAxios(url, config, this.get);
    }

    public getRequest(endpoint: string, parameters: object, token: string, bookingServiceRequest: boolean | null) {
        return bookingServiceRequest ? this.handleBookingServiceRequest(endpoint,parameters,token,this.get) : this.handleRequest(endpoint, parameters, token, this.get);
    }

    public handleBookingServiceRequest(endpoint: string, parameters: object, token: string, method: string) {
        const url = EnvironmentService.applicationConfiguration.bookingServiceBaseUrl + endpoint;
        const config = this.getConfig(token);

        return this.callAxios(url, config, method, parameters);
    }

    public postRequest(endpoint: string, parameters: object, token: string, bookingServiceRequest?: boolean | null) {
        return bookingServiceRequest ? this.handleBookingServiceRequest(endpoint, parameters, token, this.post) : this.handleRequest(endpoint, parameters, token, this.post);
    }
}
