























































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import "@/extensions/StringExtensions.ts";
import i18n from "@/i18n/index";
import { IRequestToken } from "@/store/types/Order";

export default Vue.extend({
  computed: {
    ...mapGetters(["getService", "getOrder", "getMakingOrShowingAppointment", "getCanMakeAppointment"]),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    getClock() {
      if (i18n.locale === "pl") {
        return "";
      }
      return this.$t("general.clock");
    },
    async checkSchedulability() {
      const token = await this.$recaptcha("get_schedulability");
      const recaptcha = { recaptchaToken: token } as IRequestToken;
      return await this.requestCanMakeAppointment(recaptcha);
    },
    loadAppointments() {
      if(this.getCanMakeAppointment) {
        this.$recaptcha("get_appointments").then((token: string) => {
          const recaptcha = { recaptchaToken: token } as IRequestToken;
            
          this.requestCustomerAppointments(recaptcha)
            .finally(() => {
              this.$navigateDirectTo("deliveryRestriction");
            });
        });
      } else {
        this.$navigateTo("contact"); 
      }
    },
    navigateToAppointmentBooking() {
      this.isLoading = true;
      try {
      this.checkSchedulability()
        .then(() => {
          this.loadAppointments();
        })
      } catch {
        this.$navigateTo("contact"); 
      }
    },
    ...mapActions(["requestCustomerAppointments", "requestCanMakeAppointment"]),
  },
});
