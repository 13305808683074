import { IOrderRequest } from "@/store/types/Order";
import RequestService from "./RequestService";
import { IFundamentalData } from "@/store/types/IState";
import { AxiosResponse } from "axios";
import i18n, { resolveTmsLocale } from '@/i18n/index';
import EnvironmentService from "./EnvironmentService";
import { IOrderDetailsResponse } from "./types/OrderDetailsResponse";

export class OrderService {
    private _requestService: RequestService;

    constructor() {
        this._requestService = new RequestService();
    }

    public async GetOrderDetails(payload: IOrderRequest, apiEndpoint: string): Promise<IOrderDetailsResponse>
    {
        return payload.recaptchaToken("login").then((token: string) =>
            this._requestService.postRequest(apiEndpoint, payload, token))
            .then((response: AxiosResponse<IFundamentalData>) => {
              if (response.data.loginSuccessful) {
                const country = response.data.order.address.country;
                const shortLocalCode = resolveTmsLocale(country == null ? '' : country);
                if (shortLocalCode !== 'de') {
                  i18n.locale = shortLocalCode;
                }
                const clientsExcludedFromChatBot = EnvironmentService.applicationConfiguration ? EnvironmentService.applicationConfiguration.clientsExcludedFromChatBot.split(',') : [];
                if (response.data.order.clientKey && window.$zoho && clientsExcludedFromChatBot.includes(response.data.order.clientKey)) {
                  window.$zoho.salesiq.floatbutton.visible('hide');
                  window.$zoho.salesiq.floatwindow.visible('hide');
                }
                return {result: true, data: response.data, locale: i18n.locale} as IOrderDetailsResponse;
              }
              else {
                return {result: false, data: response.data, locale: ''} as IOrderDetailsResponse;
              }

            });
    }

}

export const orderService = new OrderService();

