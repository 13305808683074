import {FeatureFlagResponse} from "@/services/types/FeatureFlagResponse";

export class FeatureFlagStore {
    private _clientKey = '';
    cache: FeatureFlagResponse[] = [];

    setClientKey(clientKey: string) {
        this._clientKey = clientKey;
    }

    getFeatureFlag(name: string) {
        const featureFlag = this.cache.find(f => f.name === name);
        if (!featureFlag) {
            return false;
        }

        if (!featureFlag.value) {
            return false;
        }

        return this._clientKey === '' || !featureFlag.canaryDeploymentOptions.length || featureFlag.canaryDeploymentOptions.includes(this._clientKey);
    }

    get BOOKING_RMD_USECLOUDENDPOINTS() {
        return this.getFeatureFlag("BOOKING_RMD_USECLOUDENDPOINTS");
    }
}
