import RequestService from "./RequestService";
import { IDeliveryRestrictionRequest } from "@/store/types/Appointment";
import { AxiosResponse } from "axios";

export class DeliveryRestrictionService {
    public _requestService: RequestService;

    constructor() {
        this._requestService = new RequestService();
    }

    public async SetDeliveryRestriction(payload: IDeliveryRestrictionRequest) {
        
        const recaptchaToken: string = await payload.recaptcha(
            "delivery_restriction"
        );

        const response: AxiosResponse = await this._requestService.postRequest(
            `SelfScheduling/DeliveryRestriction/${payload.internalOrderId}`,
            {},
            recaptchaToken,
            true
        );

        return response.data;
    }
}

export const deliveryRestrictionService = new DeliveryRestrictionService();
