















































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n/index";
import { AxiosResponse } from "axios";
import { IRequestToken } from "@/store/types/Order";

export default Vue.extend({
  components: {
    HeroText,
  },
  data() {
    return {
      captchaValidationFailed: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedAppointment", "showCompensateEmissions"]),
  },
  methods: {
    openMyClimateTab() {
      window.open(
        "https://co2.myclimate.org/de/contribution_calculators/new?localized_currency=EUR",
        "_blank",
        "noopener"
      );
    },
    downloadIcsFile() {
      this.captchaValidationFailed = false;

      this.$recaptcha("get_ics_file").then((token: string) => {
        const recaptcha = { recaptchaToken: token } as IRequestToken;

        this.requestIcsFile(recaptcha)
          .then((response: AxiosResponse<string>) => {
            const blob = new Blob([response.data], { type: "text/calendar" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = i18n.t('appointment.summary.icsFileName') as string;
            link.click();
          })
          .catch(() => {
            this.captchaValidationFailed = true;
          });
      });
    },
    ...mapActions(["requestIcsFile"]),
  },
});
