

















import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    preopened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExtended: false,
      headerHeight: 64, // initial height
      contentHeight: 0,
    };
  },
  created() {
    this.isExtended = this.preopened;

    if (!this.$slots.default)
      console.warn(
        "Accordion without content. Provide a default slot as expandable content."
      );
  },
  mounted() {
    this.calculateHeights();
    window.addEventListener("resize", this.calculateHeights);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateHeights);
  },
  methods: {
    toggle() {
      this.isExtended = !this.isExtended;
      this.$emit("extended", this.isExtended);

      // Used for AccordionGroup, and done with $parent b/c of the anonymous use via slots
      const isPartOfGroup =
        (this.$parent.$options as any)._componentTag === "RhAccordionGroup";
      if (isPartOfGroup) (this.$parent as any).closeOther(this);
    },
    calculateHeights() {
      this.headerHeight = (this.$refs.header as HTMLElement).clientHeight;
      this.contentHeight = (this.$refs.content as HTMLElement).clientHeight;
    },
  },
  computed: {
    totalHeight(): string {
      return (
        (this.isExtended
          ? this.headerHeight + this.contentHeight
          : this.headerHeight) + "px"
      );
    },
  },
});
