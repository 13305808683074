













































































































import Vue from "vue";
import Stepper from "@/components/modules/Stepper.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import "@/extensions/StringExtensions.ts";
import { IRequestToken } from "@/store/types/Order";
import moment from "moment";
import RequestService from "@/services/RequestService";
import i18n from "@/i18n/index";
import { getRouteName } from "@/routeConfig";
import { IBookableService } from "@/store/types/Service";
import EnvironmentService from "@/services/EnvironmentService";

export default Vue.extend({
  components: {
    Stepper,
  },
  data() {
    return {
      requestService: new RequestService(),
      gtcCheck: false,
      checkboxChecked: false,
      isLoading: false,
      backEndError: false,
      totalCosts: 0,
      additionalServicesPrice: 0,
      gtc: getRouteName("gtc"),
      additionalServicesError: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedAppointment", "getStepperValue", "getOrder"]),
    ...mapState(["bookedServices"]),
    requireGtcAcceptance: function (): boolean {
      return this.$i18n.locale === "de";
    },
  },
  mounted() {
    if (!this.requireGtcAcceptance) {
      // Set checkbox to true by default
      this.gtcCheck = true;
      this.checkboxChecked = true;
    }
    for (const service of this.bookedServices) {
      this.totalCosts += service.sumPrice;
    }
    this.additionalServicesPrice = this.totalCosts / 100;

    if (this.getSelectedAppointment.price > 0) {
      this.totalCosts += this.getSelectedAppointment.price;
    }
    this.gtcCheck = this.totalCosts > 0;
  },
  methods: { 
    confirmationBack() {
      if (this.getStepperValue === 3) {
        this.$navigateTo("appointmentSelection");
      } else {
        this.$navigateTo("additionalServices");
      }
    },

    confirmAppointment() {
    this.isLoading = true;
      this.$recaptcha("confirm_appointment").then((token: string) => {
        const recaptcha = { recaptchaToken: token } as IRequestToken;

        this.setCustomerAppointment(recaptcha)
          .then(() => {
            this.$recaptcha("get_order").then((recaptcha: string) => {
              const requestToken = {
                recaptchaToken: (arg: string)=> this.$recaptcha(arg),
              } as any;
              this.reloadOrderDetails(requestToken).then(() => {
                 this.saveAdditionalServices();
              }).catch(() => this.isLoading = false);
            });
          })
          .catch(() => {
            this.backEndError = true;
            this.isLoading = false;
          });
      }).catch(() => this.isLoading = false);
    },
    saveAdditionalServices() {
      // Saves additional services to the order service, without sending an email to customer service
      this.$recaptcha("book_services").then((token: string) => {
        const recaptcha = {
          recaptchaToken: token,
        } as IRequestToken;
        this.bookServices(recaptcha)
          .then(() => this.$navigateTo("appointmentSummary"))
          .catch(() => {
              this.isLoading = false;
              this.additionalServicesError = true
          });
      }).catch(() => this.isLoading = false);
    },
    saveAdditionalServicesByEmail() {
      // Send an email to customer service, asking them to add selected services
      // to the order in TMS. The order service is not informed about the changes
      this.sendAdditionalServices().then(() => this.$navigateTo("appointmentSummary"));
    },
    async sendAdditionalServices() {
      if (this.bookedServices.length > 0) {
        this.bookedServices.forEach((bookedService: IBookableService) => {

          this.$recaptcha("additionalServicesMail").then((token: string) => {
            const recaptcha = {
              recaptchaToken: token,
            } as IRequestToken;
            
            const mailObject = {
              orderNumber: this.getOrder.externalOrderNumber,
              client: this.getOrder.clientKey,
              bookedServiceKey: bookedService.key,
              date: moment(new Date()).format("DD:MM:YYYY hh:mm"),
              language: i18n.locale,
              articleCount: this.getOrder.articleCount,
              price: bookedService.price
            };

            this.requestService.postRequest(
              EnvironmentService.applicationConfiguration.additionalServicesMailApiPath,
              mailObject,
              recaptcha.recaptchaToken
            );
          });
        }) 
      }      
    },
    getLinkToGtc() {
      return { name: getRouteName("gtc") };
    },
    ...mapActions([
      "setCustomerAppointment",
      "reloadOrderDetails",
      "bookServices",
    ]),
  },
});
