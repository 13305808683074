
























import Vue from "vue";
import Stepper from "@/components/modules/Stepper.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { IRecaptchaTokenCallback, IRequestToken } from "@/store/types/Order";
import { featureFlags } from "@/store/feature-flag.store";

export default Vue.extend({
  components: {
    Stepper,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getOrder"]),
    ...mapState(["deliveryRestriction"]),
  },
  methods: {
    async sendRestriction() {
      if (!featureFlags.BOOKING_RMD_USECLOUDENDPOINTS) {
        this.isLoading = true;
        this.$recaptcha("delivery_restriction").then((token: string) => {
          const recaptcha = { recaptchaToken: token } as IRequestToken;

          this.setDeliveryRestriction(recaptcha)
            .then(() => {
              this.SET_DELIVERY_RESTRICTION(true);
            })
            .finally(() => {
              this.isLoading = false;
              this.$navigateTo("deliveryRestrictionSuccess");
            });
        });
      }
      else {
        try {
          this.isLoading = true;
          const recaptchaToken = {
            recaptchaToken: (arg: string) => this.$recaptcha(arg),
          } as IRecaptchaTokenCallback;

          await this.setDeliveryRestrictionByBookingService(recaptchaToken);
          this.SET_DELIVERY_RESTRICTION(true);

        } catch (error) {
          console.error("Error setting delivery restriction:", error);
        } finally {
          this.isLoading = false;
          this.$navigateTo("deliveryRestrictionSuccess");
        }

      }
    },
    getAppointments() {
      this.$recaptcha("get_appointments").then((token: string) => {
        const recaptcha = { recaptchaToken: token } as IRequestToken;

        this.requestCustomerAppointments(recaptcha)
          .finally(() => {
            this.$navigateTo("appointmentSelection");
          });
      });
    },
    ...mapActions(["requestCustomerAppointments", "setDeliveryRestriction", "setDeliveryRestrictionByBookingService"]),
    ...mapMutations(["SET_DELIVERY_RESTRICTION", "SET_APPOINTMENT_ERROR"]),
  },
});
